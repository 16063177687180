import { useEffect, useState } from 'react';
import { getErrorMessage } from '../../apis/services/utils';
import mainStyle from '../../style/pages/main.module.css';

export const TopNav = () => {

    const location = window.location.pathname;
    const [page, setPage] = useState('');
    const [icon, setIcon] = useState('');

    useEffect(() => {
        switch(true) {
            case location.includes('agent/chatpre'):
                setPage('젠투 에이전트');
                setIcon('/img/main/main-gentoo-agent.png');
                break;
            case location.includes('agent/custom'):
                setPage('디자인 모드');
                setIcon('/img/main/main-designmode.png');
                break;
            case location.includes('dashboard'):
                setPage('대시보드');
                setIcon('/img/main/main-dashboard.png');
                break;
            case location.includes('analytics'):
                setPage('분석');
                setIcon('/img/main/main-analytics.png');
                break;
            case location.includes('plans'):
                setPage('요금');
                setIcon('/img/main/main-plans.png');
                break;
            case location.includes('experiment'):
                setPage('실험실');
                setIcon('/img/main/main-experiment.png');
                break;
                case location.includes('setting'):
                    setPage('일반');
                    setIcon('/img/main/main-general.png');
                    break;
            default:
                setPage('젠투 에이전트');
                setIcon('/img/main/main-gentoo-agent.png');
                break;
        }
    }, [location])

    return (
        <div className={mainStyle.TopNav__Container}>
            <div className={mainStyle.TopNav__Wrap}>
                <img src={icon} width={20} height={20} />
                <div className='spacing-8' />
                <p className='h6 fc-gray-700'>{page}</p>
                <div className='spacing-24' />
                {getErrorMessage(location)}
            </div>
        </div>
    )
}
