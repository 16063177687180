import { useState, useRef, useEffect } from "react";
import unitsStyle from '../../style/units.module.css';
import ReactDOM from 'react-dom';

export const CustomTooltip = (props) => {
    const { tipText } = props;
    const [tipVisible, setTipVisible] = useState(false);
    const iconRef = useRef(null);

    const handleMouseEnter = () => {
        setTipVisible(true);
    }

    const handleMouseLeave = () => {
        setTipVisible(false);
    }

    useEffect(() => {
        const handleScroll = () => {
            setTipVisible(false);
        };

        window.addEventListener('scroll', handleScroll, true);

        return () => {
            window.removeEventListener('scroll', handleScroll, true);
        };
    }, []);

    const TipBox = () => {
        if (!iconRef.current) return null;
        
        const rect = iconRef.current.getBoundingClientRect();
        return ReactDOM.createPortal(
            <div 
                className={unitsStyle.CustomTooltip__TipBox}
                style={{
                    '--rect-bottom': rect.bottom,
                    '--rect-left': rect.left,
                    '--rect-width': rect.width,
                }}
            >
                <p className={`${unitsStyle.CustomTooltip__TipText} h10 fc-gray-300`}>
                    {tipText}
                </p>
            </div>,
            document.body
        );
    };

    return (
        <div className={unitsStyle.CustomTooltip__Wrap}>
            <div 
                ref={iconRef}
                className={unitsStyle.CustomTooltip__Icon} 
                onMouseEnter={handleMouseEnter} 
                onMouseLeave={handleMouseLeave}
            >
                <img src="/img/units/tooltip-question-mark.png" width={20} height={20} padding={4} />
            </div>
            {tipVisible && <TipBox />}
        </div>
    )
}

export const CustomRefreshTooltip = (props) => {
    const { tipText, isGenerating } = props;
    const [tipVisible, setTipVisible] = useState(false);

    const handleMouseEnter = () => {
        setTipVisible(true);
    }

    const handleMouseLeave = () => {
        setTipVisible(false);
    }

    useEffect(() => {
        if (isGenerating) {
            setTipVisible(false);
        }
    }, [isGenerating]);

    return (
        <div className={unitsStyle.CustomTooltip__RefreshWrap}>
            {
                isGenerating ?
                <img src='/img/units/spinner_28px.gif' width={28} height={28} className='default-cursor'/> :
                <img src='/img/units/refresh-default.png' width={28} height={28} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className='pointer'/>
            }
            {
                tipVisible ?
                <div className={unitsStyle.CustomTooltip__RefreshTipBox}>
                    <p className={`${unitsStyle.CustomTooltip__RefreshTipText} h12 fc-gray-300`}>
                        {tipText}
                    </p>
                </div> :
                null
            }
        </div>
    )
}