import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ containerId }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const container = document.getElementById(containerId);
    if (container) {
      setTimeout(() => {
        container.scrollTo({
            top: 0,
            behavior: 'instant'
        });
      }, 0); 
    }
  }, [pathname, containerId]);

  return null;
};

export default ScrollToTop;
