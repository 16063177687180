import loginStyle from '../style/login.module.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login, loginTest, signup } from '../apis/api/auth/api';
import { getAccessLevel, storeInitData } from '../apis/services/init';
import { InputField } from '../components/Units/InputField';
import { DefaultButton } from '../components/Units/Button';
import { fetchCustomPrompt } from '../apis/api/experiments/api';
import { fetchExperimentInfo } from '../apis/services/create/api';

const Login = (props) => {
    const navigate = useNavigate();

    const [inputId, setInputId] = useState('');
    const [inputPW, setInputPW] = useState('');

    const handleInputId = (e) => {
        setInputId(e.target.value);
    };
    
    const handleInputPW = (e) => {
        setInputPW(e.target.value);
    };

    const handleEnter = (key) => {
        if (key === 'Enter') {
            if (inputId === '') {alert('아이디를 입력하세요.')}
            else if (inputPW === '') {alert('비밀번호를 입력하세요.')}
            else {handleLogin();}
        }
    }

    const handleLogin = () => {
        login(inputId, inputPW)
            .then(res => {
                if (res.statusCode === 302) {
                    window.location.href = res.url;
                } else if (res.statusCode === 400) {
                    alert('아이디 또는 비밀번호를 다시 확인하세요.');
                } else {
                    storeInitData(res);
                    sessionStorage.setItem('email', inputId);
                    fetchExperimentInfo(res.partnerId, res.chatBotList[0].chatBotId)
                        .then(res => {
                            sessionStorage.setItem('cptres', JSON.stringify(res));
                            const customPromptFeature = res.find(feature => feature.key === 'customPrompt');
                            const customPromptActivated = customPromptFeature ? customPromptFeature.activated : false;
                            sessionStorage.setItem('cpt', customPromptActivated);
                            navigate('/home');
                        })
                }
            })
            .catch(error => alert(error))
    };

    return (
        <div className={loginStyle.Login__Container}>
            <div className={loginStyle.Login__Left}>
                <div className={loginStyle.Login__ImageWrap}>
                    <img 
                        src='/img/login/login-graphic.png'
                        className={loginStyle.Login__Image}
                    />
                </div>
            </div>
            <div className={loginStyle.Login__Right}>
                <img src='/img/units/gentoo-symbol.png' width={56} height={56}/>
                <div className='spacing-24' />
                <p className='h4 fc-gray-700'>고객과 대화하며 고객을 이해하는</p>
                <p className='h4 fc-gray-700'>인게이지먼트 솔루션, 젠투</p>
                <div className='spacing-40' />
                <div className={loginStyle.Login__InputWrap}>
                    <InputField 
                        name={'email'} 
                        type={'email'} 
                        labelText={'이메일(ID)'} 
                        placeholderText={'이메일을 입력해 주세요'} 
                        callbackFunc={handleEnter}
                        storeFunc={setInputId}
                        validation={false} 
                    />
                </div>
                <div className='spacing-20' />
                <div className={loginStyle.Login__InputWrap}>
                    <InputField 
                        name={'password'} 
                        type={'password'} 
                        labelText={'비밀번호'} 
                        placeholderText={'비밀번호를 입력해 주세요'} 
                        callbackFunc={handleEnter}
                        storeFunc={setInputPW} 
                        subText={'비밀번호를 잊어버리셨나요?'}
                        subFunc={() => navigate('/reset')}
                        validation={false}
                    />
                </div>
                <div className='spacing-24' />
                <div className={loginStyle.Login__BtnWrap}>
                    <DefaultButton 
                        buttonText={'로그인'} disabled={inputId.length === 0 || inputPW.length === 0} 
                        onClick={(e) => {
                            handleLogin(e)
                        }}
                        hasIcon={false}
                        disabledTextClassName={'h7 fc-gray-500'}
                        activeTextClassName={'h7 fc-white'}
                    />
                </div>
                <div className='spacing-24' />
                <div className='spacing-16' />
                <p className='h7 fc-gray-500'>기업 회원이 아니신가요?</p>
                <div className='spacing-8' />
                <button
                    className={loginStyle.Login__DemoButton}
                    onClick={(e) => {
                        e.preventDefault();
                        window.location.href = 'https://demo.gentooai.com/mockup/dlst'
                    }}
                >
                    <p className='h6 fc-gray-800'>데모 체험으로 바로가기🚀</p>
                </button>
            </div>
        </div>
    )
};

export default Login