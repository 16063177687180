import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import deployPreviewStyle from '../style/deployPreview.module.css';

const DeployPreview = () => {
    const [searchParams] = useSearchParams();
    const isMobile = searchParams.get('isMobile') === 'true';
    const posX = searchParams.get('posX') || 0;
    const posY = searchParams.get('posY') || 66;

    return (
        <>
            {
                isMobile ?
                <div className={deployPreviewStyle.Mobile__Container}>
                    <img 
                        src='/img/deployPreview/background-mobile.png' 
                        className={deployPreviewStyle.Mobile__Background} 
                        alt="background"
                    />
                    <img 
                        src='/img/units/deploypreview-fb-mobile.gif' 
                        className={deployPreviewStyle.Mobile__Fb}
                        style ={{
                            '--posX': posX,
                            '--posY': posY
                        }} 
                        alt="gentoo-floating-preview"
                    />
                </div> :
                <div className={deployPreviewStyle.Desktop__Container}>
                    <img 
                        src='/img/deployPreview/background.svg' 
                        className={deployPreviewStyle.Desktop__Background} 
                        alt="background"
                    />
                    <img 
                        src='/img/units/deploypreview-fb-desktop.gif' 
                        className={deployPreviewStyle.Desktop__Fb}
                        style={{
                            '--posX': posX,
                            '--posY': posY
                        }} 
                        alt="gentoo-floating-preview"
                    />
                </div>
            }
        </>
    )
}

export default DeployPreview;