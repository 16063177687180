
export const getAccessLevel = (planType) => {
    switch (planType) {
        case 'ENTERPRISE':
            return 4;
        case 'EXPERT':
            return 3;
        case 'ADVANCED':
            return 2;
        case 'PRO':
            return 1;
        default:
            return 0;
    }
}

export const storeInitData = (obj) => {
    const partnerId = sessionStorage.getItem('ptid');
    sessionStorage.setItem('ptid', partnerId || obj.partnerId);
    sessionStorage.setItem('sid', obj.shopId);
    sessionStorage.setItem('ptt', obj.partnerType);
    sessionStorage.setItem('l', getAccessLevel(obj.planType));
    sessionStorage.setItem('cbid', obj.chatBotList[0]?.chatBotId);
    sessionStorage.setItem('initializeState', obj.initializeState);
    sessionStorage.setItem('isAnalyticsReady', obj.isAnalyticsReady);
    sessionStorage.setItem('isDeployed', obj.isDeployed);
    sessionStorage.setItem('durl', obj.dashboardURL);
    sessionStorage.setItem('expirationTime', obj.planExpirationTime);
    sessionStorage.setItem('ca', obj.chatBotList[0].chatAgent);
    sessionStorage.setItem('planBillingType', obj.planBillingType || (getAccessLevel(obj.planType) > 0 ? 'MONTHLY' : null));    // 결제고객인데 null 일 때 기본값은 일단 월결제
}