import { useState } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { TopNav } from '../Main/TopNav';
import settingStyle from '../../style/setting.module.css';
import mainStyle from '../../style/pages/main.module.css';
import unitStyle from '../../style/units.module.css';
import ScrollToTop from '../Units/ScrollToTop';

const Setting = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = window.location.pathname;
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const partnerId = sessionStorage.getItem('ptid');
    const userAccessLevel = sessionStorage.getItem('l');
    const initializeState = sessionStorage.getItem('initializeState');

    const routes = {
        top: [
            {
                id: 0,
                path: '/setting',
                name: '일반',
                imgSrc: '/img/main/main-general.png',
                accessLevel: 0,
            },
            {
                id: 1,
                path: '/setting/plans',
                name: '요금',
                imgSrc: '/img/main/main-plans.png',
                accessLevel: 0,
            },
            /* {
                id: 2,
                path: '/setting/usage',
                name: '사용량',
                imgSrc: '/img/main/main-usage.png',
                accessLevel: 0,
            }, */
        ],
        bottom: [
            {
                id: 0,
                path: '',
                name: '로그아웃',
                imgSrc: '/img/main/main-logout.png',
                accessLevel: 0,
            }
        ]
    }

    const handleNavigate = (path) => {
        navigate(path);
    }

    const handleToggleSubMenu = (id) => {
        setActiveSubMenu(activeSubMenu === id ? null : id);
    }

    const handleGoBack = () => {
        const previousPath = sessionStorage.getItem('previousPath');
        if (previousPath) {
            navigate(previousPath);
            sessionStorage.removeItem('previousPath');
        } else {
            navigate(-1);
        }
    }

    const handleLogout = (e) => {
        e.preventDefault();
        sessionStorage.clear();
        navigate('/login')
    }
    
    return (
        <>
            <div className={mainStyle.Main__Background}>
                <div className={mainStyle.Main__Wrap}>
                    <div className={mainStyle.Main__SideBar__Wrap}>
                        <div className={mainStyle.Main__SideBar}>
                            <div className={mainStyle.Main__SideBar__TopMenuBox}>
                                <div style={{ display: 'flex', width: '100%', alignItems: 'center', padding: '16px', cursor: 'pointer' }} onClick={handleGoBack}>
                                    <img width={18} height={18} src='/img/units/arrow-back.png'/>
                                    <div className='spacing-16' />
                                    <p className='h9 fc-gray-600'>뒤로가기</p>
                                </div>
                                <div className='spacing-16' />
                                <div>
                                    <div style={{display: 'flex', width: '198px', height: '40px', padding: '12px 16px', alignItems: 'center'}}>
                                        <p className='h11 fc-gray-500'>설정</p>
                                    </div>
                                    {routes.top.map((route, idx) => (
                                        (route.accessLevel <= Number(userAccessLevel) &&
                                            <div className={mainStyle.Main__List} key={idx}>
                                                <div className={unitStyle.Flex__HLeft}>
                                                    <input
                                                        className={mainStyle.Main__List__MenuInput}
                                                        id={`menu-top-${route.id + 1}`}
                                                        type='radio'
                                                        name='menu'
                                                        onClick={() => {
                                                            handleNavigate(route.path);
                                                            if (route.child) {
                                                                handleToggleSubMenu(route.id)
                                                            }
                                                        }}
                                                        checked={!route.child && pathname === route.path}
                                                        onChange={() => {}}
                                                    />
                                                    <label className={`${mainStyle.Main__List__MenuLabel} pointer`} htmlFor={`menu-top-${route.id + 1}`}>
                                                        <img src={route.imgSrc} width={18} />
                                                        <div className='spacing-16' />
                                                        <p className='h9 fc-gray-600'>{route.name}</p>
                                                        {
                                                            route.child &&
                                                            <>
                                                                <div className='spacing-16' />
                                                                <img src='/img/main/main-downarrow.png' width={24} height={24} />
                                                            </>
                                                        }
                                                    </label>
                                                </div>
                                                {route.child && activeSubMenu === route.id && (
                                                    <div className={mainStyle.Main__SubMenu}>
                                                        {route.child.map((subRoute, subIdx) => (
                                                            <div className={mainStyle.Main__List} key={subIdx}>
                                                                <input
                                                                    className={mainStyle.Main__List__MenuInput}
                                                                    id={`submenu${subRoute.id}`}
                                                                    type='radio'
                                                                    name='submenu'
                                                                    onClick={() => handleNavigate(subRoute.path)}
                                                                    checked={pathname === subRoute.path}
                                                                    onChange={() => {}}
                                                                />
                                                                <label className={`${mainStyle.Main__List__SubLabel__Content} pointer`} htmlFor={`submenu${subRoute.id}`}>
                                                                    <div className='spacing-16' />
                                                                    <p className='h7 fc-gray-600'>{subRoute.name}</p>
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    ))}
                                </div>
                            </div>
                            <div className={mainStyle.Main__SideBar__BottomMenuBox}>
                                <div className={mainStyle.Main__List}>
                                    <div className={unitStyle.Flex__HLeft}>
                                        <input className={mainStyle.Main__List__MenuInput} id={`menu-bottom-${routes.bottom[0].id + 1}`} type='radio' name='menu' />
                                        <label className={`${mainStyle.Main__List__MenuLabel} pointer`} htmlFor={`menu-bottom-${routes.bottom[0].id + 1}`} onClick={handleLogout}>
                                            <img src={routes.bottom[0].imgSrc} width={18} />
                                            <div className='spacing-16' />
                                            <p className='h9 fc-gray-600'>{routes.bottom[0].name}</p>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={mainStyle.Main__Container}>
                        <TopNav />
                        <div className={mainStyle.Main__OutletWrap} id="outlet-wrap">
                            <ScrollToTop containerId="outlet-wrap"/>
                            <Outlet className={mainStyle.Main__Outlet}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Setting;