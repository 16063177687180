
import unitsStyle from '../../style/units.module.css';

export const ConfirmModal = (props) => {
    const {title, subText, submitFunc, cancelFunc, confirmText, cancelText, setIsModalVisible, buttonColor = '#154cca'} = props;

    return (
        <div className={unitsStyle.ConfirmModal__Container}>
            <p className="h4 fc-gray-700">{title}</p>
            <div className="spacing-12" />
            <p className="h7 fc-gray-700">{subText}</p>
            <div className="spacing-32" />
            <div className={unitsStyle.ConfirmModal__BtnWrap}>
                <button 
                    className={unitsStyle.ConfirmModal__CancelBtn}
                    onClick={() => {
                        cancelFunc()
                        setIsModalVisible(false)
                    }}
                >
                    <p className="h6 fc-gray-600">{cancelText}</p>
                </button>
                <div className="spacing-8" />
                <button 
                    className={unitsStyle.ConfirmModal__ConfirmBtn}
                    style={{backgroundColor: buttonColor}}
                    onClick={() => {
                        submitFunc()
                        setIsModalVisible(false)
                    }}
                >
                    <p className="h6 fc-white">{confirmText}</p>
                </button>
            </div>
        </div>
    )
}

export const InfoModal = (props) => {
    const {title, subText, confirmText, setIsModalVisible} = props;

    return (
        <div className={unitsStyle.InfoModal__Container}>
            <p className="h4 fc-gray-700">{title}</p>
            <div className="spacing-12" />
            <p className="h7 fc-gray-700">{subText}</p>
            <div className="spacing-32" />
            <div className={unitsStyle.InfoModal__BtnWrap}>
                <button 
                    className={unitsStyle.ConfirmModal__ConfirmBtn}
                    onClick={() => {
                        setIsModalVisible(false)
                    }}
                >
                    <p className="h6 fc-white">{confirmText}</p>
                </button>
            </div>
        </div>
    )
}