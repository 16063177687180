import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"
import { getAccountInfo, postAdminRedirect } from "../apis/api/auth/api";
import { storeInitData } from "../apis/services/init";

const Transaction = () => {
    const queryString = window.location.search;
    const [searchParams, setSearchParams] = new useSearchParams(queryString);

    const accountObj = {
        provider: searchParams.get('provider'),
        isPartner: JSON.parse(searchParams.get('isPartner')),
        isRedirect: JSON.parse(searchParams.get('isRedirect')),
        partnerId: searchParams.get('partnerId'),
        shopId: searchParams.get('shopId'),
        mallId: searchParams.get('mallId'),
        godomallMallId: searchParams.get('godomallMallId'),
        email: searchParams.get('email'),
        phone: searchParams.get('phone'),
        shopUserId: searchParams.get('userId'),
        shopName: searchParams.get('shopName'),
        companyName: searchParams.get('companyName'),
        presidentName: searchParams.get('presidentName'),
        mallUrl: searchParams.get('mallUrl'),
        baseDomain: searchParams.get('baseDomain'),
    }

    sessionStorage.setItem('accountObj', JSON.stringify(accountObj));
    const partnerId = searchParams.get('partnerId');

    const navigate = useNavigate();

    useEffect(() => {
        if (partnerId) {
            sessionStorage.setItem('ptid', partnerId);
            getAccountInfo(partnerId)
                .then(res => storeInitData(res))
                .then(navigate('/home'));
        } else if (accountObj.isRedirect) {
            if (accountObj.isPartner) {
                navigate('/login');
            } else {
                navigate('/signup');
            }
        } else {
            if (accountObj.isPartner) {
                navigate('/login');
                // TODO: 자동 로그인 추가
            } else {
                navigate('/login');
            }
        }
    }, [])
}

export default Transaction;