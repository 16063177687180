import axios from "axios";

export const upsertXLSX = async (formData, setCheckState) => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_DEMO_UPLOAD_DATA_URL, 
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
        )
        setCheckState([true, true]);
        return {
            status: true,
            example: response.data.data.example,
        };
    } catch (error) {
        alert(`Error while calling upsertXLSX API: ${error.response.data.message}`);
        console.error(`Error while calling upsertXLSX API: ${error.response.data.message}`);
    }
}

export const convertImg = async (formData) => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_DEMO_CONVERT_IMG_URL,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
        )
        return response.data.imageUrl;
    } catch (error) {
        console.error(`Error while calling convertImg API: ${error}`);
    }
}

export const fetchChatbotInfo = async (partnerId, chatbotId) => {
    try {
        const response = await axios.get(
            process.env.REACT_APP_CHATBOT_URL + `/${partnerId}/${chatbotId}`,
            {
                params: {
                    partnerId: partnerId,
                    chatbotId: chatbotId,
                },
                headers: {}
            }
        )
        return response.data || false;
    } catch (error) {
        console.error(`Error while calling fetchChatbotList API: ${error}`)
    }
}

export const updateChatbotInfo = async (partnerId, chatbotId = '37', settings) => {
    try {
        const response = await axios.put(
            process.env.REACT_APP_CHATBOT_URL + `/${partnerId}/${chatbotId}`,
            {
                chatbotId: chatbotId,
                name: settings.name,
                profileImg: settings.profileImg,
                greetingMessage: settings.greetingMessage,
                colorCode: settings.colorCode,
                carouselType: settings.carouselType,
                exceptKeyword: settings.exceptKeyword,
                examples: settings.examples,
                position: settings.position,
                mobilePosition: settings.mobilePosition
            },
            {
                params: {
                    partnerId: partnerId,
                    chatbotId: chatbotId,
                },
                headers: {}
            }
        )
    } catch (error) {
        console.error(`Erro while calling updateChatbotInfo api: ${error}`);
    }
}

export const generateStartExample = async (partnerId, count = '3') => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_CHATBOT_URL + `/start-example/${partnerId}`,
            {  
                count
            },
            {
                headers: {}
            }
        )
        return response.data;
    } catch (error) {
        console.error(`Error while calling start-example api: ${error}`);
    }
}

export const fetchExperimentInfo = async (partnerId, chatbotId) => {
    try {
        const response = await axios.get(
            process.env.REACT_APP_EXPERIMENT_URL + `/${partnerId}/${chatbotId}`,
            {
                params: {
                    partnerId: partnerId,
                    chatbotId: chatbotId,
                },
                headers: {}
            }
        )
        return response.data.dataList || false;
    } catch (error) {
        console.error(`Error while calling fetchExperimentInfo api: ${error}`);
    }
}

export const updateExperimentInfo = async (partnerId, chatbotId, settings) => {
    const detailedResponse = { value: settings?.detailedResponse?.value, activated: settings?.detailedResponse?.activated };
    const customPrompt = { value: settings?.customPrompt?.value, activated: settings?.customPrompt?.activated };
    const warningMessage = { value: settings?.warningMessage?.value, activated: settings?.warningMessage?.activated };
    try {
        const response = await axios.put(
            process.env.REACT_APP_EXPERIMENT_URL + `/${partnerId}/${chatbotId}`,
            {
                detailedResponse,
                customPrompt,
                warningMessage
            },
            {
                params: {
                    partnerId: partnerId,
                    chatbotId: chatbotId,
                },
                headers: {}
            }
        )
        return response.data;
    } catch (error) {
        console.error(`Error while calling updateExperimentInfo api: ${error}`);
    }
}