import alertsStyle from '../../style/units.module.css';

export const InfoMessage = (props) => {
    return (
        <div className={alertsStyle.Alerts__InfoMsg__Container}>
            <div style={{display: 'flex'}}>
                <img src="/img/units/info.png" width={16} height={16} />
                <div className="spacing-4" />
                <p className="h10 fc-gray-600">{props.text}</p>
            </div>
        </div>
    )
}

export const ErrorMessage = (props) => {
    return (
        <div className={alertsStyle.Alerts__ErrorMsg__Container}>
            <div className={alertsStyle.Alerts__ErrorMsg__Wrap}>
                <img src="/img/units/error.png" width={16} height={16} />
                <div className="spacing-4" />
                <p className={`h12 ${alertsStyle.Alerts__ErrorMsg__Text}`}>{props.text}</p>
            </div>
        </div>
    )
}

export const ErrorText = (props) => {
    return (
        <div className={alertsStyle.Alerts__ErrorText__Wrap}>
            <img src="/img/units/toast-error.png" width={16} height={16} />
            <div className="spacing-4" />
            <p className={`h10 fc-warning ${alertsStyle.Alerts__ErrorText__Text}`}>{props.text}</p>
        </div>
    )
}