import { useEffect, useState } from "react"
import widgetStyle from '../../style/units.module.css';
import agentStyle from '../../style/agent.module.css';
import { ErrorMessage } from "../Units/Alerts";
import { PositionRangeInput } from "../Units/CustomSet";

export const WidgetSet = (props) => {
    const {compProps} = props;
    const clickedButton = compProps.clickedButton;
    const setClickedButton = compProps.setClickedButton;

    const handleButtonClick = (id) => {
        const buttonId = id;
        let clickedButtonCopy = [...clickedButton];
        clickedButtonCopy[buttonId] = !clickedButtonCopy[buttonId];
        setClickedButton(clickedButtonCopy);
    }

    return (
        <div>
            <div
                className={widgetStyle.WidgetSet__Container}
            >
                <button
                    data-buttonid={0}
                    className={clickedButton[0] ? widgetStyle.WidgetSet__Button__active : widgetStyle.WidgetSet__Button}
                    onClick={() => {handleButtonClick(0)}}  // 텍스트 clickable 하도록 하기 위해 id 직접 넘기는 방식으로 수정했습니다
                >
                    <p className={`h6 ${clickedButton[0] ? 'fc-prim-800': 'fc-gray-700'}`} >플로팅 버튼</p>
                </button>
                <div className="spacing-8" />
                <button
                    className={widgetStyle.WidgetSet__Button__disabled}
                    disabled={true}
                >
                    <p className="h6 fc-gray-400">추천 섹션(준비 중인 기능이에요)</p>
                </button>
            </div>
            {
                clickedButton[0] &&
                <>
                    <div className={widgetStyle.WidgetSet__Divider} >
                        <div className="spacing-32" />
                    </div>
                    <div>
                        <PosCustom compProps={compProps} />
                    </div>
                </>
            }
        </div>
    )
}

export const PosCustom = (props) => {
    const {compProps} = props;
    const setIsToastVisible = compProps.setIsToastVisible;
    const initSettings = compProps.initSettings;
    const updateSetting = compProps.updateSetting;
    const setIsModalVisible = compProps.setIsModalVisible;
    const userAccessLevel = sessionStorage.getItem('l');
    const setUnsavedChanges = compProps.setUnsavedChanges;

    const [isMobile, setIsMobile] = useState(false);
    const [fbPos, setFbPos] = useState(
        (initSettings?.position && Object.keys(initSettings.position).length > 0) 
            ? initSettings.position 
            : {
                top: null,
                bottom: 66,
                left: null,
                right: 0,
            }
    );
    const [mobileFbPos, setMobileFbPos] = useState(
        (initSettings?.mobilePosition && Object.keys(initSettings.mobilePosition).length > 0) 
            ? initSettings.mobilePosition 
            : {
                top: null,
                bottom: 66,
                left: null,
                right: 0,
            }
    );
    const [deployedPositions, setDeployedPositions] = useState([fbPos, mobileFbPos]);

    useEffect(() => {
        updateSetting('position', fbPos);
    }, [fbPos])

    useEffect(() => {
        updateSetting('mobilePosition', mobileFbPos);
    }, [mobileFbPos])

    const handleDtTabClick = () => {
        if (isMobile) {
            setIsMobile(false);
        }
    }

    const handleMoTabClick = () => {
        if (!isMobile) {
            setIsMobile(true);
        }
    }

    return (
        <div>
            <div className={widgetStyle.Accodian__TopWrapper} style={{paddingLeft: 0, paddingRight: 0}}>
                <div className={widgetStyle.Accodian__BadgeWrapper}>
                    <div
                        className={widgetStyle.Accodian__TopBadge__secondary}
                    >
                        <p className="h8 fc-gray-500">위젯설정</p>
                    </div>
                    <div className="spacing-12" />
                    <p className="h4 fc-gray-700">플로팅 버튼</p>
                </div>
                <div style={{ visibility: 'hidden' }}>
                    <ErrorMessage text={`추천 섹션은 아래의 배포 버튼을 통해 자동으로 배포되지 않습니다.\n다음 HTML 코드를 복사하여 원하는 화면의 해당 위치에 직접 삽입 해야 합니다`} />
                </div>
            </div>
            <div className={widgetStyle.WidgetSet__FbPos__Container}>
                {
                    isMobile ?
                    <div className={widgetStyle.WidgetSet__FbPos__ScreenBg__mo}>
                        <div className={widgetStyle.WidgetSet__FbPos__AppScreen__mo}>
                            <img 
                                src="/img/units/designmode-fb-mobile.png"
                                className={widgetStyle.WidgetSet__FbPos__FbImg__mo}
                                style={{ '--fb-pos-bottom': mobileFbPos.bottom, '--fb-pos-right': mobileFbPos.right }}
                                alt='mobile floating button'
                            />
                        </div>
                    </div>
                    :
                    <div className={widgetStyle.WidgetSet__FbPos__ScreenBg__dt}>
                        <div className={widgetStyle.WidgetSet__FbPos__AppScreen__dt}>
                            <img 
                                src="/img/units/designmode-fb-desktop.png" 
                                className={widgetStyle.WidgetSet__FbPos__FbImg__dt}
                                style={{ '--fb-pos-bottom': fbPos.bottom, '--fb-pos-right': fbPos.right }}
                                alt='desktop floating button'
                            />
                        </div>
                    </div>
                }
                <div className="spacing-48" />
                <div className={widgetStyle.WidgetSet__FbPos__ButtonSettingWrap}>
                    <div className={widgetStyle.WidgetSet__FbPos__TabGroup}>
                        <div className={isMobile ? widgetStyle.WidgetSet__FbPos__TabWrap__unselected : widgetStyle.WidgetSet__FbPos__TabWrap__selected} onClick={handleDtTabClick}>
                            <div className={isMobile ? widgetStyle.WidgetSet__FbPos__Tab__unselected : widgetStyle.WidgetSet__FbPos__Tab__selected}>
                                <p className={isMobile ? "h9 fc-gray-500" : "h8 fc-prim-800"} style={{textAlign: 'center'}}>데스크탑</p>
                            </div>
                        </div>
                        <div className={isMobile ? widgetStyle.WidgetSet__FbPos__TabWrap__selected : widgetStyle.WidgetSet__FbPos__TabWrap__unselected} onClick={handleMoTabClick}>
                            <div className={isMobile ? widgetStyle.WidgetSet__FbPos__Tab__selected : widgetStyle.WidgetSet__FbPos__Tab__unselected}>
                                <p className={isMobile ? "h8 fc-prim-800" : "h9 fc-gray-500"} style={{textAlign: 'center'}}>모바일</p>
                            </div>
                        </div>
                    </div>
                    <div className="spacing-40" />
                    <div className={widgetStyle.WidgetSet__FbPos__ControlWrap}>
                        <p className="h6 fc-gray-600">위치 조정</p>
                        <div className="spacing-20" />
                        <PositionRangeInput 
                            label="가로"
                            axis="right"
                            positions={isMobile ? mobileFbPos : fbPos}
                            deployedPositions={isMobile ? deployedPositions[1] : deployedPositions[0]}
                            setPositions={isMobile ? setMobileFbPos : setFbPos}
                            //disabled={isMobile ? true : false}
                            disabled={false}
                            max={isMobile ? 96 : 200}
                        />
                        <div className="spacing-24" />
                        <PositionRangeInput 
                            label="세로"
                            axis="bottom"
                            positions={isMobile ? mobileFbPos : fbPos}
                            deployedPositions={isMobile ? deployedPositions[1] : deployedPositions[0]}
                            setPositions={isMobile ? setMobileFbPos : setFbPos}
                            max={200}
                        />
                    </div>
                </div>
            </div>
            <div className="spacing-48" />
            <div className={agentStyle.AgentCustom__ButtonWrap}>
                <button 
                    className={widgetStyle.WidgetSet__PreviewButton}
                    onClick={() => {
                        if (isMobile) {
                            window.open(`/deploy-preview?isMobile=${isMobile}&posX=${mobileFbPos.right}&posY=${mobileFbPos.bottom}`, '_blank');
                        } else {
                            window.open(`/deploy-preview?isMobile=${isMobile}&posX=${fbPos.right}&posY=${fbPos.bottom}`, '_blank');
                        }
                    }}
                >
                    <p className="h4 fc-prim-800">미리보기</p>
                    <img src='/img/deployPreview/preview-open.png' width={24} height={24} />
                </button>
                <div className="spacing-8" />
                {
                    Number(userAccessLevel) === 0 ?
                    <button 
                        className={agentStyle.AgentCustom__Button} 
                        onClick={() => window.open('https://whattime.co.kr/waddle/pricing-consult', "_blank")}  
                    >
                        <p className='h4 fc-white'>유료 전환하고 배포하기</p>
                    </button> :
                    <button 
                        className={agentStyle.AgentCustom__Button} 
                        onClick={() => setIsModalVisible(true)}
                    >
                        <p className='h4 fc-white'>배포</p>
                    </button>
                }
            </div>
            <div className="spacing-24" />
        </div>
    )
}