import { useEffect } from 'react';

// 창 닫기 (document navigation) 감지 blocker
export const usePreventClose = ({ unsavedChanges }) => {
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (unsavedChanges) {
                event.preventDefault();
                event.returnValue = '';
            }
        }
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }
    }, [unsavedChanges])
}