import customStyle from '../../style/units.module.css';
import { useEffect, useState, useRef } from 'react';
import { ChromePicker, SketchPicker } from 'react-color';
import { uploadImg } from '../../apis/services/create/utils';
import { CustomTooltip, CustomRefreshTooltip } from './CustomTooltip';
import { generateStartExample } from '../../apis/services/create/api';
import { CustomColorPicker } from './CustomColorPicker';

export const LogoInput = (props) => {
    const {initLogo, updateSetting} = props;
    const checkFileSize = (e) => {
        const fileSize = e.target?.files[0]?.size;
        const sizeToMB = (fileSize / (1024 * 1024)).toFixed(1);

        if (sizeToMB > 2) {
            alert('2MB 이하 파일만 업로드 가능합니다');
        } else {
            uploadImg(e.target?.files[0], updateSetting);
        }
    }

    return (
        <div className={customStyle.CustomSet__Container}>
            <p className='h6 fc-gray-600 w-fit-content'>프로필</p>
            <div className='spacing-8' />
            <div>
                <label htmlFor='profile' style={{position: 'relative'}}>
                    <img 
                        src='/img/units/preview-logo-selection.png'
                        className={customStyle.CustomSet__LogoInput__Img}
                    />
                    <img src={initLogo} width={80} height={80} className='pointer'/>
                </label>
                <input 
                    type='file' id='profile' name='profile' accept='.png, .jpg' 
                    className='visually-hidden' 
                    onChange={(e) => checkFileSize(e)}
                />
                <div className='spacing-8' />
                <p className='h12 fc-gray-500' >png, jpg 형식, 2MB 이하 파일만 업로드 가능합니다</p>
            </div>
        </div>
    )
}

export const NameInput = (props) => {
    const {initName, updateSetting} = props;

    return (
        <div className={customStyle.CustomSet__Container}>
            <p className='h6 fc-gray-600 w-fit-content'>에이전트 이름</p>
            <div className='spacing-8' />
            <div className={customStyle.CustomSet__TextInputWrap}>
                <input 
                    type='text' 
                    defaultValue={initName}
                    className={customStyle.CustomSet__TextInput}
                    onChange={(e) => updateSetting('name', e.target.value)}
                />
            </div>
        </div>
    )
}

export const ColorInput = (props) => {
    const {initColor, updateSetting, setCarouselVisible} = props;
    const [background, setBackground] = useState(initColor);
    const [openPicker, setOpenPicker] = useState([false, false, false]);
    const [selected, setSelected] = useState();

    const colorTooltipText = ["사용자 발화 말풍선의 배경색과 예시 버튼의 텍스트 색 등 다양한 요소에 사용되는 대표 색상입니다. 가독성을 위해 명도가 높은 색상은 피하는 것을 권장합니다", 
        "예시 버튼에 마우스를 올렸을 때 변경되는 배경색으로 사용됩니다", 
        "예시 버튼의 배경색으로 사용됩니다"];
    const colorName = ["대표색", "버튼 호버색", "버튼 배경색"]

    const handleChangeComplete = (e, idx) => {
        const newColor = {
            hex: e.hex,
            rgb: e.rgb,
            red: e.rgb.r,
            green: e.rgb.g,
            blue: e.rgb.b,
            alpha: e.rgb.a,
        }
        let newColorInput = [...initColor];
        newColorInput[idx] = newColor;

        updateSetting('colorCode', newColorInput);
    };

    const handleOpenPicker = (e, idx) => {
        // e?.stopPropagation();
        let copy = [...openPicker];
        copy = [false, false, false];
        copy[idx] = true;
        setSelected(idx);
        setOpenPicker(copy);
        setCarouselVisible(false);
    }

    const handleClosePicker = (e) => {
        e?.stopPropagation();
        setSelected();
        setOpenPicker([false, false, false]);
    }
    
    useEffect(() => {
        setBackground(initColor);
        setSelected(initColor);
    }, [initColor])

    return (
        <div className={customStyle.CustomSet__Container}>
            <p className='h6 fc-gray-600 w-fit-content'>테마 색상</p>
            <div className='spacing-8' />
            <div className={customStyle.CustomSet__ColorInput__Wrap}>
                {
                    background?.map((data, idx) => {
                        return (
                            <div key={idx} className={customStyle.CustomSet__ColorInput__Block}>
                                <div className={`${selected === idx ? customStyle.CustomSet__ColorInput__active : customStyle.CustomSet__ColorInput} pointer`}  onClick={(e) => handleOpenPicker(e, idx)} >
                                    <div className={customStyle.CustomSet__ColorInput__Circle} style={{background: `rgba(${data.red}, ${data.green}, ${data.blue}, ${data.alpha})`}} />
                                    <img 
                                        src={`${selected === idx ? '/img/units/custom-expanded.png' : '/img/units/custom-expand.png'}`} width={24} height={24} 
                                    />
                                </div>
                                <div className={customStyle.CustomSet__ColorInput__NameWrap}>
                                    <p className='h12 fc-gray-500' style={{padding: '4px'}}>{colorName[idx]}</p>
                                    <CustomTooltip tipText={colorTooltipText[idx]}/>
                                </div>
                                {
                                    openPicker[idx] ?
                                    <>
                                        <div className={customStyle.CustomSet__ColorInput__PickerWrap}>
                                            <CustomColorPicker 
                                                hex={ background[idx].hex }
                                            onChange={ (e) => handleChangeComplete(e, idx) }
                                        />
                                    </div>
                                    <div className={customStyle.CustomSet__ColorInput__PickerClose} onClick={(e) => {handleClosePicker(e)}} />
                                    </>
                                    : 
                                    null
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export const GreetingInput = (props) => {
    const {initGreeting, updateSetting} = props;

    return (
        <div className={customStyle.CustomSet__Container}>
            <p className='h6 fc-gray-600 w-fit-content'>시작 문구</p>
            <div className='spacing-8' />
            <div className={customStyle.CustomSet__TextInputWrap}>
                <input 
                    type='text' 
                    defaultValue={initGreeting}
                    className={customStyle.CustomSet__TextInput}
                    onChange={(e) => updateSetting('greetingMessage', e.target.value)}
                />
            </div>
        </div>
    )
}

export const ExamplesInput = (props) => {
    const {initExamples, updateSetting, partnerId} = props;
    const [examples, setExamples] = useState(
        initExamples?.map((text, idx) => ({
            id: idx + 1,
            text,
            generating: false,
            error: false,
        })) || []
    );
    const generatingText = "문장을 조립하고 있습니다...";
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const newExamples = initExamples?.map((text, idx) => ({
            id: idx + 1,
            text,
            generating: false,
            error: false,
        })) || [];

        setExamples((prevExamples) => {
            const isIdentical =
                prevExamples.length === newExamples.length &&
                prevExamples.every((ex, index) => ex.text === newExamples[index].text);
            
            return isIdentical ? prevExamples : newExamples;
        });
    }, [initExamples])

    useEffect(() => {
        if (examples && examples.length > 0) {
            const errorExamples = examples.filter(ex => ex.error);
            setIsError(errorExamples.length !== 0);
            updateSetting('examples', examples.map((ex) => ex.text));
        }
    }, [examples]);

    const handleAddExample = () => {
        if (examples.length < 7) {
            const newExample = {
                id: Date.now(), // unique ID
                text: generatingText,
                generating: true,
                error: false,
            };

            setExamples(prevExamples => [...prevExamples, newExample]);
            generateStartExample(partnerId, 1)
                .then(res => {
                    const newExampleText = res?.examples?.[0] || null;
                    setExamples((prev) =>
                        prev.map((ex) =>
                            ex.id === newExample.id
                                ? (
                                    newExampleText ?
                                    { ...ex, text: newExampleText, generating: false, error: false } :
                                    { ...ex, text: newExampleText, generating: false, error: true }
                                )
                                : ex
                        )
                    );             
                })
                .catch(() => {
                    setExamples((prev) =>
                        prev.map((ex) =>
                            ex.id === newExample.id
                                ? { ...ex, text: "생성에 실패했습니다. 다시 시도해 주세요.", generating: false, error: true }
                                : ex
                        )
                    );
                });
        }
    }

    const handleGenerate = (id) => {
        setExamples((prev) =>
            prev.map((ex) =>
                ex.id === id
                    ? { ...ex, text: generatingText, generating: true, error: false }
                    : ex
            )
        );
        generateStartExample(partnerId, 1)
            .then(res => {
                const newExampleText = res?.examples?.[0] || null;
                setExamples((prev) => 
                    prev.map((ex) =>
                        ex.id === id
                        ? (
                            newExampleText ?
                            { ...ex, text: newExampleText, generating: false, error: false } : 
                            { ...ex, text: newExampleText, generating: false, error: true }
                        )
                        : ex
                    )
                );  
            })
            .catch(() => {
                setExamples((prev) =>
                    prev.map((ex) =>
                        ex.id === id
                            ? { ...ex, text: "생성에 실패했습니다. 다시 시도해 주세요.", generating: false, error: true }
                            : ex
                    )
                );
            });
    }

    const handleDeleteExample = (id) => {
        if (examples?.length > 3) {
            setExamples(prevExamples => prevExamples.filter((ex) => ex.id !== id));
        }
    }

    return (
        <div className={customStyle.CustomSet__Container}>
            <div className={customStyle.CustomSet__Title}>
                <p className='h6 fc-gray-600 w-fit-content'>시작 예시 버튼</p>
                <CustomTooltip tipText={"예시 버튼은 콘솔에 표시되는 순서와 관계없이 무작위로 보여집니다"} />
            </div>
            <div className='spacing-8' />
            <div className={customStyle.CustomSet__ExInput__Wrap}>
                <div className={customStyle.CustomSet__ExInput__ExList}>
                    {
                        examples?.map((ex, idx) => {
                            return (
                                <div key={ex.id} className={customStyle.CustomSet__ExInput__ExWrap}>
                                    <div key={ex.id} className={`${ex.error ? customStyle.CustomSet__ExInput__ExBox__error : customStyle.CustomSet__ExInput__ExBox__default}`}>
                                        <div className={customStyle.CustomSet__ExInput__ExIndex}>
                                            <p className='h9 fc-gray-500'>
                                                {idx + 1}
                                            </p>
                                        </div>
                                        <div className={customStyle.CustomSet__ExInput__ExTextWrap}>
                                            <p className={`h7 ${ex.generating ? 'fc-gray-400' : 'fc-gray-800'} ${customStyle.CustomSet__ExInput__ExText}`}>
                                                {ex.text}
                                            </p>
                                        </div>
                                        <div onClick={() => handleGenerate(ex.id)}>
                                            <CustomRefreshTooltip tipText="예시 문구 재생성" isGenerating={ex.generating}/>
                                        </div> 
                                    </div>
                                    <div className='spacing-8'/>
                                    { examples?.length > 3 ? 
                                        <div className={customStyle.CustomSet__ExInput__ExRemove} onClick={() => {handleDeleteExample(ex.id)}}>
                                            <img src='/img/units/close.png' width={12} height={12} alt='' className='pointer'/> 
                                        </div>
                                        : 
                                        <div className={customStyle.CustomSet__ExInput__padding}/>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <div className='spacing-12' />
                <div className={customStyle.CustomSet__ExInput__AddBtnWrap}>
                    {
                        examples?.length < 7 ?
                        <div className={customStyle.CustomSet__ExInput__AddBtn__default} onClick={handleAddExample}>
                            <p className='h7 fc-gray-800'>항목 추가</p>
                        </div> :
                        <div className={customStyle.CustomSet__ExInput__AddBtn__disabled}>
                            <p className='h7 fc-gray-500'>항목 추가</p>
                        </div>
                    }
                </div>
                <div className='spacing-12' />
                {
                    isError ?
                    <div className={customStyle.CustomSet__ExInput__ErrorWrap}>
                        <p className='h13 fc-warning'>• 생성에 실패했습니다. 다시 시도해 주세요.</p>
                    </div> : null
                }
            </div>
        </div>
    )
}

export const CarouselTypeInput = (props) => {
    const {initCarouselType, updateSetting, setCarouselVisible} = props;
    const [selected, setSelected] = useState(initCarouselType);

    const handleClickInput = (e) => {
        e.stopPropagation();
        const value = e.target.value;
        updateSetting('carouselType', value);
        setSelected(value);
        setCarouselVisible(true);
    }

    useEffect(() => {
        setSelected(initCarouselType);
    }, [initCarouselType])

    return (
        <div className={customStyle.CustomSet__Container}>
            <div className={customStyle.CustomSet__Title}>
                <p className='h6 fc-gray-600 w-fit-content'>상품 표시 유형</p>
                <CustomTooltip tipText={"고객이 상품 추천을 요청했을 때 사용되는 답변 형태입니다"} />
            </div>
            <div className='spacing-8' />
            <div className={customStyle.CustomSet__CrsTypeScrollWrap}>
                <div className={customStyle.CustomSet__CrsTypeScroll}>
                    <div className={customStyle.CustomSet__CrsTypeItems}>
                        <div className={customStyle.CustomSet__CrsTypeItem}>
                            <label htmlFor='single'>
                                <div className={`${customStyle.CustomSet__CrsType__single} ${selected === 'single' && customStyle.CustomSet__CrsType__activate}`}>
                                    <img src='/img/units/custom-carousel-single.png' width={114} />
                                </div>
                            </label>
                            <input 
                                type='radio' id='single' name='carousel-type' value='single'
                                className='visually-hidden'
                                onClick={(e) => {handleClickInput(e)}}
                            />
                            <div className='spacing-8' />
                            <div className={customStyle.CustomSet__CrsType__NameWrap}>
                                <p className={`h7 ${selected === 'single' ? 'fc-prim-800' : 'fc-gray-600'}`}>단독형</p>
                                <CustomTooltip tipText={"상품 N개를 추천하며, 한 화면에 1개만 표시해 집중도를 높입니다."} />
                            </div>
                        </div>
                        <div className='spacing-8'/>
                        <div className={customStyle.CustomSet__CrsTypeItem}>
                            <label htmlFor='carousel-horizontal'>
                                <div className={`${customStyle.CustomSet__CrsType__horizontal} ${selected === 'carousel-horizontal' && customStyle.CustomSet__CrsType__activate}`}>
                                    <img src='/img/units/custom-carousel-horizontal.png' width={114} />
                                </div>
                            </label>
                            <input 
                                type='radio' id='carousel-horizontal' name='carousel-type' value='carousel-horizontal'
                                className='visually-hidden'
                                onClick={(e) => {handleClickInput(e)}}
                            />
                            <div className='spacing-8' />
                            <div className={customStyle.CustomSet__CrsType__NameWrap}>
                                <p className={`h7 ${selected === 'carousel-horizontal' ? 'fc-prim-800' : 'fc-gray-600'}`}>가로형</p>
                                <CustomTooltip tipText={"상품 N개를 추천하며, 화면에는 한 번에 3개씩 목록 형태로 표시합니다. 텍스트 위주로 상품을 보여주고자 할 때 적합합니다"} />
                            </div>
                        </div>
                        <div className='spacing-8'/>
                        <div className={customStyle.CustomSet__CrsTypeItem}>
                            <label htmlFor='carousel-vertical'>
                                <div className={`${customStyle.CustomSet__CrsType__vertical} ${selected === 'carousel-vertical' && customStyle.CustomSet__CrsType__activate}`}>
                                    <img src='/img/units/custom-carousel-vertical.png' width={114} />
                                </div>
                            </label>
                            <input 
                                type='radio' id='carousel-vertical' name='carousel-type' value='carousel-vertical'
                                className='visually-hidden'
                                onClick={(e) => {handleClickInput(e)}}
                            />
                            <div className='spacing-8' />
                            <div className={customStyle.CustomSet__CrsType__NameWrap}>
                                <p className={`h7 ${selected === 'carousel-vertical' ? 'fc-prim-800' : 'fc-gray-600'}`}>세로형</p>
                                <CustomTooltip tipText={"상품 N개를 추천하며, 화면에는 한 번에 3개씩 세로 형태로 표시합니다. 이미지 위주로 상품을 보여주고자 할 때 적합합니다"} />
                            </div>
                        </div>
                    </div>
                    <div className='spacing-8'/>
                </div>
            </div>
        </div>
    )
}

export const ExceptKeyword = (props) => {
    const {initKeywords, updateSetting} = props;
    const [isInputFocused, setisInputFocused] = useState(false);
    const [input, setInput] = useState('');
    const [inputArr, setInputArr] = useState(initKeywords);

    const handleFocusInput = () => {
        setisInputFocused(true);
    }

    const handleBlurInput = () => {
        if (input.length === 0) setisInputFocused(false);
    }

    const handleChangeInput = (e) => {
        setInput(e.target.value);
    }

    const handleClickAdd = (e) => {
        if (input.length === 0) return;
        let arr = [...inputArr];
        arr.push(input);
        setInput('');
        setInputArr(arr);
        updateSetting('exceptKeyword', arr);
        setisInputFocused(false);
    }

    const handleClickDelete = (idx) => {
        let arr = [...inputArr];
        arr.splice(idx, 1);
        setInputArr(arr);
        updateSetting('exceptKeyword', arr);
    }

    return (
        <div className={customStyle.CustomSet__Container}>
            <p style={{width: '56px'}} className='h6 fc-gray-600'>제외 키워드</p>
            <div className='spacing-32' />
            <div style={{width: '100%'}}>
                <div className={customStyle.CustomSet__ExKeywords__TextInputWrap} style={{border: isInputFocused && `1px solid #154cca`}}>
                    <input 
                        type='text' 
                        placeholder=''
                        className={customStyle.CustomSet__ExKeywords__TextInput}
                        onFocus={() => handleFocusInput()}
                        onBlur={() => handleBlurInput()}
                        onChange={(e) => handleChangeInput(e)}
                        value={input}
                    />
                    {
                        isInputFocused &&
                        <img 
                            src='/img/units/custom-add.png' width={24} height={24}
                            onClick={(e) => handleClickAdd(e)}
                        />
                    }
                </div>
                <div className='spacing-8' />
                <div className={customStyle.CustomSet__ExKeywords__KeywordsWrap}>
                    {
                        inputArr.map((keyword, idx) => {
                            return (
                                    <div key={idx} className={customStyle.CustomSet__ExKeywords__Keyword}>
                                        <p className='h7 fc-gray-700'>{keyword}</p>
                                        <img 
                                            src='/img/units/toast-close.png' width={24} height={24} 
                                            onClick={() => handleClickDelete(idx)}
                                        />
                                    </div>
                                )
                            }
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export const PositionRangeInput = (props) => {
    const { label, axis, positions, deployedPositions, setPositions, disabled, max } = props;
    const value = positions[axis];
    const deployedValue = deployedPositions[axis];
    const [inputWidth, setInputWidth] = useState(0);
    const [isThumbHovered, setIsThumbHovered] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        const updateWidth = () => {
            if (inputRef.current) {
                setInputWidth(inputRef.current.offsetWidth);
            }
        };
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    const handleMouseMove = (e) => {
        if (!inputRef.current) return;

        const rect = inputRef.current.getBoundingClientRect();
        const thumbPosition = ((value/max) * (inputWidth - 24) + 12);
        const mouseX = e.clientX - rect.left;
        
        const thumbWidth = 24;
        const isHoveringThumb = Math.abs(mouseX - thumbPosition) <= thumbWidth / 2;
        
        setIsThumbHovered(isHoveringThumb);
    };

    const handleMouseLeave = () => {
        setIsThumbHovered(false);
    };

    return (
        <div className={customStyle.CustomRange__Container}>
            <label htmlFor={`pos${axis}`} className={`h6 fc-gray-500 ${customStyle.CustomRange__Label}`}>
                {label}
            </label>
            <div className="spacing-16" />
            <div className={customStyle.CustomRange__RangeWrap}>
                <div className={customStyle.CustomRange__MarkerContainer}>
                    <div 
                        className={customStyle.CustomRange__Marker}
                        style={{ '--deployed-position': `${(deployedValue/max) * (inputWidth - 24) + 12}px`}}
                    />
                </div>
                <input 
                    ref={inputRef}
                    type="range" 
                    name={`pos${axis}`}
                    className={customStyle.CustomRange__Input}
                    min={0}
                    max={max}
                    step={1}
                    value={value}
                    style={{
                        '--range-progress': `${(value / max) * 100}%`,
                    }}
                    onChange={(e) => {
                        setPositions(res => ({
                            ...res,
                            [axis]: Number(e.target.value),
                        }))
                    }}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave} 
                    disabled={disabled}
                />
                {isThumbHovered && 
                    <div 
                        className={customStyle.CustomRange__Tooltip}
                        style={{
                            '--position-value': value,
                            '--input-width': `${inputWidth}px`,
                            '--max': max,
                        }}
                    >
                        <p className="h12 fc-white">{value}</p>
                    </div>
                }
            </div>
        </div>
    );
}
