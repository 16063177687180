import { useSearchParams } from "react-router-dom";
import styles from "../../style/plans.module.css";

export const FailPage = ({paymentInfo, setPaymentInfo}) => {

    const errorCode = paymentInfo.paymentStatus.params.code;
    const errorMessage = paymentInfo.paymentStatus.params.message;

    return (
        <div className={styles.Checkout__Wrap}>
            <div className={styles.Fail__Wrap}>
                <img
                    src="https://static.toss.im/lotties/error-spot-apng.png"
                    width="120"
                    height="120"
                />
                <h2 className={styles.Checkout__Title}>결제를 실패했어요</h2>
                <div className={styles.Checkout__ResponseSection}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span className={styles.Checkout__ResponseLabel}>code</span>
                        <span id="error-code" className={styles.Checkout__ResponseText}>
                            {errorCode}
                        </span>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span className={styles.Checkout__ResponseLabel}>message</span>
                        <span id="error-message" className={styles.Checkout__ResponseText}>
                            {errorMessage}
                        </span>
                    </div>
                </div>
                <div className={styles.Checkout__ButtonGroup}>
                    <a
                        className={styles.Checkout__Btn}
                        href="https://developers.tosspayments.com/sandbox"
                        target="_blank"
                        rel="noreferrer noopener"
                        style = {{ textDecoration: "none", textAlign: "center", width: "auto" }}
                    >
                    다시 테스트하기
                    </a>
                    <div style={{ display: "flex", gap: "16px" }}>
                        <a
                            className={styles.Checkout__Btn}
                            href="https://docs.tosspayments.com/reference/error-codes"
                            target="_blank"
                            rel="noreferrer noopener"
                            style = {{ textDecoration: "none", textAlign: "center" }}
                        >
                            에러코드 문서보기
                        </a>
                        <a
                            className={styles.Checkout__Btn}
                            href="https://techchat.tosspayments.com"
                            target="_blank"
                            rel="noreferrer noopener"
                            style = {{ textDecoration: "none", textAlign: "center" }}
                        >
                            실시간 문의하기
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}