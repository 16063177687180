import styled from 'styled-components';
import { colors } from '../style/colorVar';
import unitsStyle from '../style/units.module.css';

const Prep = (props) => {
    const { smallText, betaBtn, betaBtnLink } = props;
    return (
        <div className={unitsStyle.Prep__Container}>
            <div className={unitsStyle.Prep__Wrap}>
                <img src='/img/units/prep-img.png' width={250} height={182} />
                <div className='spacing-20' />
                <div className={unitsStyle.Prep__TextWrap}>
                    <p className='h3 fc-gray-700'>현재 페이지 준비중입니다</p>
                    <div className='spacing-8' />
                    <p className={`h7 fc-gray-700 ${unitsStyle.Prep__SmallText}`}>
                        {smallText}
                    </p>
                </div>
                <div className='spacing-20' />
                {
                    betaBtn && 
                    <a href={betaBtnLink} target='_blank' rel='noopener noreferrer'>
                        <div className={unitsStyle.Prep__BetaCta}>
                            <p className='h4 fc-white'>기능 살펴보기</p>
                        </div>
                    </a>
                }
            </div>
        </div>
    )
};

export default Prep