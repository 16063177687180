import React, { useState, useEffect } from 'react';
import { TopAccodian } from '../Units/Accodian';
import { BetaFeatures } from './BetaFeatures';
import { experimentInitSettings } from '../../data/unitsData';
import { fetchExperimentInfo } from '../../apis/services/create/api';
import Toast from '../Units/Toast';
import { ConfirmModal } from '../Units/Modal';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';
import designModeStyle from '../../style/designMode.module.css';
import { usePreventClose } from '../../hooks/usePreventClose.js';
import expStyle from '../../style/experiment.module.css';
import { ConsoleFooter } from '../Units/ConsoleFooter.jsx';

const Experiment = () => {
    const partnerId = sessionStorage.getItem('ptid');
    const chatbotId = sessionStorage.getItem('cbid');
    const userAccessLevel = sessionStorage.getItem('l');
    const [initExpSettings, setInitExpSettings] = useState();
    const [isContentOpen, setIsContentOpen] = useState(true);
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    // 창 닫기 (document navigation) 감지 blocker
    usePreventClose({ unsavedChanges });

    // 라우팅 네비게이션 blocker
    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => 
            unsavedChanges &&
            currentLocation.pathname !== nextLocation.pathname
    )

    const handleConfirm = () => {
        blocker.proceed();
        setUnsavedChanges(false);
    }

    // 유료 플랜: active - off -> active - on
    // 미결제: disabled - off -> redirection

    const updateExpSetting = (key, newValue) => {
        setInitExpSettings((prev) => ({
            ...prev,
            [key]: newValue,
        }));
    }

    useEffect(() => {
        fetchExperimentInfo(partnerId, chatbotId)
            .then(res => {
                if (!res) {
                    res = experimentInitSettings;
                    updateExpSetting('detailedResponse', res.detailedResponse);
                    updateExpSetting('customPrompt', res.customPrompt);
                } else {
                    if (!res.detailedResponse) {
                        res.detailedResponse = experimentInitSettings.detailedResponse;
                    }
                    if (!res.customPrompt) {
                        res.customPrompt = experimentInitSettings.customPrompt;
                    }
                    res.forEach(feature => {
                        updateExpSetting(feature.key, feature);
                    })
                }
            })
    }, []);

    useEffect(() => {
    }, [initExpSettings]);
 
    const betaProps = {
        initExpSettings: initExpSettings,
        updateExpSetting: updateExpSetting,
        isContentOpen: isContentOpen,
        setIsContentOpen: setIsContentOpen,
        userAccessLevel: userAccessLevel,
        setIsToastVisible: setIsToastVisible,
        setUnsavedChanges: setUnsavedChanges,
    }

    return (
        <>
            {
                blocker.state === "blocked" &&
                <div className={designModeStyle.Modal__Background}>
                    <div className={designModeStyle.Modal__Wrap}>
                        <ConfirmModal 
                            title={'페이지를 나가시겠습니까?'} 
                            subText={'변경사항이 저장되지 않을 수 있습니다'} 
                            submitFunc={handleConfirm} 
                            cancelFunc={() => blocker.reset()}
                            setIsModalVisible={() => {}}
                            confirmText={'나가기'}
                            cancelText={'취소'}
                        />
                    </div>
                </div>
            }
            <div className={expStyle.Experiment__Container}>
                <div className={expStyle.Experiment__MinHeightWrap}>
                    <div className={expStyle.Experiment__Wrap}>
                        <TopAccodian 
                            title={'기능 설정'} 
                            badgeText={'Beta'} 
                            ContentComp={BetaFeatures}
                            subText={'출시 이전의 베타 기능을 먼저 체험해보세요'}
                            compProps={betaProps}
                        />
                    </div>
                </div>
                <ConsoleFooter />
                {
                    isToastVisible &&
                    <div className={expStyle.Toast__Block}>
                        <Toast status={'success'} message={'변경 사항이 반영되었습니다'} isLinkable={false} isVisible={isToastVisible} setIsVisible={setIsToastVisible} />
                    </div>
                }
            </div>
        </>
    );
};

export default Experiment;