import React, {useState, forwardRef} from "react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../../style/react-datepicker.css';
import unitsStyle from '../../style/units.module.css';

const DateRangePicker = (props) => {
    const { startDate, endDate, setStartDate, setEndDate } = props;
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);

    const handleCalendarClose = () => {
        setIsCalendarOpen(false);
    }

    const handleCalendarOpen = () => {
        setIsCalendarOpen(true);
    }

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
      };

    const CustomPicker = forwardRef(
        ({ value, onClick, className }, ref) => (
            <div 
                className={className} 
                onClick={onClick} 
                ref={ref} 
            >
                <p className='h10 fc-gray-700'>
                {value}
                </p>
                <img src={isCalendarOpen ? '/img/units/calendar-on.png' : '/img/units/calendar-off.png'} width={26} height={26} alt=''/>
            </div>
        )
    );

    return (
        <div className={unitsStyle.DateRangePicker__Container}>
            <div className={unitsStyle.DateRangePicker__Wrap}>
                <DatePicker
                    selected={startDate}
                    dateFormat="yyyy/MM/dd"
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    maxDate={new Date()}
                    customInput={<CustomPicker className={unitsStyle.DateRangePicker__CustomPicker}/>}
                    onCalendarClose={handleCalendarClose}
                    onCalendarOpen={handleCalendarOpen}
                />
            </div>
        </div>
    );
}

export default DateRangePicker;