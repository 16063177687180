import { useState } from 'react';
import unitsStyle from '../../style/units.module.css';

const Toast = (props) => {
    const { status, message, isLinkable, isVisible, setIsVisible } = props;
    let statusImg = '';

    switch (status) {
        case 'error':
            statusImg = '/img/units/toast-error.png';
            break;
        case 'success':
            statusImg = '/img/units/toast-success.png';
            break;
        case 'copy':
            statusImg = '/img/units/toast-copy.png';
            break;
        default:
            statusImg = '/img/units/toast-error.png';
    }

    return (
        <div className={isVisible ? unitsStyle.Toast__Container : 'hide'}>
            <div className={unitsStyle.Toast__Background}>
                <img src={statusImg} 
                    width={24}
                />
                <div className='spacing-16' />
                <p className='h6 fc-white'>{message}</p>
                {
                    isLinkable &&
                    <>
                        <div className='spacing-16' />
                        <a href='/'>
                            <button 
                                className={unitsStyle.Toast__Button}
                            >
                                <p className='h6'>바로가기</p>
                            </button>
                        </a>
                    </>
                }
                <div className='spacing-16' />
                <img 
                    src='/img/units/toast-close.png' 
                    width={24}
                    className='pointer'
                    onClick={() => setIsVisible(false)}
                />
            </div>
        </div>
    )
}

export default Toast