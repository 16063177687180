import { loadTossPayments, ANONYMOUS } from "@tosspayments/tosspayments-sdk";
import { useEffect, useState } from "react";
import styles from "../../style/plans.module.css";

const CheckoutPage = ({paymentInfo, setPaymentInfo}) => {
    const clientKey = process.env.REACT_APP_TOSS_PAYMENT_TEST_CLIENT_KEY;

    const { amount, shopId, itemName, orderId, setPaymentStatus, paymentKey } = paymentInfo;

    const [ready, setReady] = useState(false);
    const [widgets, setWidgets] = useState(null);
    const [amountCurrency, setAmountCurrency] = useState({
        currency: "KRW",
        value: amount,
    });


    useEffect(() => {
        async function fetchPaymentWidgets() {
            // ------  결제위젯 초기화 ------
            const tossPayments = await loadTossPayments(clientKey);
            // 회원 결제
            const widgets = tossPayments.widgets({
                customerKey: shopId,
            });
            // 비회원 결제
            //const widgets = tossPayments.widgets({ customerKey: ANONYMOUS });
            setWidgets(widgets);
        }

        fetchPaymentWidgets();
    }, [clientKey, shopId]);

    useEffect(() => {
        async function renderPaymentWidgets() {
            if (widgets == null) {
                return;
            }
            /**
             * 위젯의 결제금액을 결제하려는 금액으로 초기화하세요.
             * renderPaymentMethods, renderAgreement, requestPayment 보다 반드시 선행되어야 합니다.
             * @docs https://docs.tosspayments.com/sdk/v2/js#widgetssetamount
             */
            await widgets.setAmount(amountCurrency);

            await Promise.all([
                /**
                 * 결제창을 렌더링합니다.
                 * @docs https://docs.tosspayments.com/sdk/v2/js#widgetsrenderpaymentmethods
                 */
                widgets.renderPaymentMethods({
                    selector: "#payment-method",
                    // 렌더링하고 싶은 결제 UI의 variantKey
                    // 결제 수단 및 스타일이 다른 멀티 UI를 직접 만들고 싶다면 계약이 필요해요.
                    // @docs https://docs.tosspayments.com/guides/v2/payment-widget/admin#새로운-결제-ui-추가하기
                    variantKey: "DEFAULT",
                }),
                /**
                 * 약관을 렌더링합니다.
                 * @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자-옵션
                 */
                widgets.renderAgreement({
                    selector: "#agreement",
                    variantKey: "AGREEMENT",
                }),
            ]);

            setReady(true);
        }

        renderPaymentWidgets();
    }, [widgets]);

    useEffect(() => {
        if (widgets == null) {
            return;
        }
      
        widgets.setAmount(amountCurrency);
    }, [widgets, amountCurrency]);

    return (
    <div className={styles.Checkout__Wrap}>
        <div className={styles.Checkout__Container}>
            {/* 결제 UI */}
            <div id="payment-method" className='w-100' />
            {/* 이용약관 UI */}
            <div id="agreement" className='w-100' />
            <div className={styles.Checkout__BtnWrap}>
                {/* 결제하기 버튼 */}
                <button
                    className={ready ? styles.Checkout__Btn__primary : styles.Checkout__Btn}
                    disabled={!ready}
                    onClick={async () => {
                        try {
                            /**
                             * 결제 요청
                             * 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
                             * 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
                             * @docs https://docs.tosspayments.com/sdk/v2/js#widgetsrequestpayment
                             */
                            // Redirect 방식이 아니라 Promise 방식으로 처리 (mobile 불가)
                            widgets?.requestPayment({
                                orderId: orderId,
                                orderName: itemName,
                                /* customerName: "김토스",
                                customerEmail: "customer123@gmail.com", */
                            })
                            .then(res => {
                                const newPaymentStatus = { status: 'success', params: res };
                                setPaymentStatus(newPaymentStatus);
                                setPaymentInfo({ ...paymentInfo, paymentStatus: newPaymentStatus, paymentKey: res.paymentKey });
                            })
                            .catch(err => {
                                console.error("tosspayments requestPayment error", err);
                                const newPaymentStatus = { status: 'fail', params: err };
                                setPaymentStatus(newPaymentStatus);
                                setPaymentInfo({ ...paymentInfo, paymentStatus: newPaymentStatus, paymentKey: null });
                            });
                        } catch (error) {
                            // TODO: 에러 처리
                            console.error(error);
                        }
                    }}
                >
                결제하기
                </button>
            </div>
        </div>
    </div>
    );

}

export default CheckoutPage;