export const ToastContents = {
    error_file_type: '파일 형식이 맞지 않아요',
    error_upload: '파일이 업로드 되지 않았어요',
    success_create: '젠투 에이전트가 생성되었어요. 지금 바로 확인해보세요!',
    success_update: '업데이트 완료!',
    success_ai_update: 'AI 에이전트 정보가 업데이트 되었습니다!',
    copy_link: '링크를 클립보드에 복사했어요!',
}

export const TipContents = {
    1: `고객이 상품 추천을 요청했을 때 사용되는 답변 형태입니다. 좌측 미리보기에서 젠투에게 'OO한 상품 찾아줘'와 같이 질문해 답변 형태를 확인해 보세요`,
    2: '가장 관련도가 높은 상품 1개와 해당 상품의 관련 상품 N개를 추천합니다',
    3: '가장 관련도가 높은 상품 1개를 추천합니다',
    4: '특정 주제나 단어를 제외하여 대화 내용을 조절할 수 있어요',
    5: '️방금 만든 에이전트를 바로 사용해보세요!',
}

export const chatBotInitSettings = {
    profileImg: '/img/units/gentoo-symbol.png',
    name: '젠투',
    colorCode: [
        {
            hex: '#154cca',
            rgb: {
                r: 21,
                g: 76,
                b: 202,
                a: 1,
            },
            red: 21,
            green: 76,
            blue: 202,
            alpha: 1,
        },
        {
            hex: '#bbc8e5',
            rgb: {
                r: 187,
                g: 200,
                b: 229,
                a: 1,
            },
            red: 187,
            green: 200,
            blue: 229,
            alpha: 1,
        },
        {
            hex: '#e0e6f3',
            rgb: {
                r: 224,
                g: 230,
                b: 243,
                a: 1,
            },
            red: 224,
            green: 230,
            blue: 243,
            alpha: 1,
        },
    ],
    greetingMessage: '안녕하세요 🧐 어떤 상품을 찾아드릴까요? 젠투가 추천해드릴게요.',
    carouselType: 'single',
    exceptKeyword: [],
    examples: ['예시문구1','예시문구2','예시문구3'],
    position: {
        top: null,
        bottom: 66,
        left: null,
        right: 0,
    },
    mobilePosition: {
        top: null,
        bottom: 66,
        left: null,
        right: 0,
    },
};

export const experimentInitSettings = {
    detailedResponse: {
        value: null,
        activated: false,
        title: '더 자세히 답변하기',
        description: '젠투의 기본 답변 길이를 3문장에서 최대 5문장으로 늘려, 더 풍부하고 자세한 답변을 제공하도록 하는 기능입니다',
    },
    customPrompt: {
        value: null,
        activated: false,
        title: '커스텀 프롬프트 설정하기',
        description: '원하는 대화 스타일과 목적에 맞게 에이전트의 응답 방식을 설정하여 우리 쇼핑몰에 최적화된 에이전트를 만들어보세요',
    },
}