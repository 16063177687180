import { useEffect, useState } from "react";
import unitsStyle from '../../style/units.module.css';

const Onboarding = (props) => {
    const {setIsOnboardingEnable} = props;
    const initializeState = sessionStorage.getItem('initializeState');
    const isAnalyticsReady = sessionStorage.getItem('isAnalyticsReady');
    const userAccessLevel = sessionStorage.getItem('l');
    const [buttonText, setButtonText] = useState();

    const imgSrc = (
        Number(initializeState) < 2 ?
        '/img/units/onboarding-step1.svg' :
        (
            Number(userAccessLevel) === 0 ?
            '/img/units/onboarding-step2.svg' :
            '/img/units/onboarding-step3.svg'
        )
    )

    useEffect(() => {
        if (Number(initializeState) < 2) {
            setButtonText('콘솔 둘러보기');
        } else if (Number(initializeState) === 2 && Number(userAccessLevel) < 1) {
            setButtonText('젠투 에이전트 사용하기');
        } else {
            setButtonText('젠투 에이전트 배포하러 가기');
        }
    }, [])

    return (
        <div 
            className={unitsStyle.Onboarding__DimBg}
            onClick={()=>{
                setIsOnboardingEnable(false)
                sessionStorage.setItem('onboard', false);
            }}
        >
            <div className={unitsStyle.Onboarding__Container}>
                <div className={unitsStyle.Onboarding__Header}>
                    <div className={unitsStyle.Onboarding__Title}>
                        <img src="/img/units/onboarding-title.png" width={24} height={24} />
                        <div className="spacing-8" />
                        <p className="h4 fc-gray-700">젠투 도입 절차 안내</p>
                    </div>
                    <img src="/img/units/close.png" width={19} height={19} className='pointer'/>
                </div>
                <img src={imgSrc} width={740} height={486} />
                <div className={unitsStyle.Onboarding__Footer}>
                    <button 
                        className={unitsStyle.Onboarding__Btn}
                        onClick={()=>{
                            setIsOnboardingEnable(false)
                            sessionStorage.setItem('onboard', false);
                        }}
                    >
                        <p className="h6 fc-white">{buttonText}</p>
                        <img src="/img/units/arrow-forward.png" width={20} height={20} />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Onboarding;