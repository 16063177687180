import unitsStyle from '../../style/units.module.css';
import plansStyle from '../../style/plans.module.css';
import { useState, useEffect } from 'react';
import { usagePlans, dataSubTables, consoleItems, consoleSubTables } from '../../data/usagePlans.js';
import CheckoutPage from './Checkout.jsx';
import { SuccessPage } from './Success.jsx';
import { FailPage } from './Fail.jsx';
import { requestPayment } from '../../apis/api/payment/api.js';
import { ConsoleFooterSpacer, ConsoleFooter } from '../Units/ConsoleFooter.jsx';

const Plans = () => {
    const userAccessLevel = sessionStorage.getItem('l');
    const planBillingType = sessionStorage.getItem('planBillingType');
    const ptid = sessionStorage.getItem('ptid');
    const shopId = sessionStorage.getItem('sid');
    const [isMonthly, setIsMonthly] = useState(planBillingType === 'MONTHLY');
    const [modalState, setModalState] = useState({status: false, ctaLink: ''});
    const [selectedPlanId, setSelectedPlanId] = useState(0);
    const [paymentStatus, setPaymentStatus] = useState(null); // 'success', 'fail', or null (e.g. { status: 'success', params: res })
    const [paymentInfo, setPaymentInfo] = useState({
        amount: 50000,
        shopId: shopId,
        itemName: '요금제 결제',
        orderId: null,
        paymentStatus: paymentStatus,
        setPaymentStatus: setPaymentStatus,
        paymentKey: null,
    });
    const isProd = window.location.hostname === 'console.gentooai.com';
    const isGentoo = ptid === '6718be2310310e41ab5276ef';

    useEffect(() => {
        if (Number(userAccessLevel) > 3) {
            setSelectedPlanId(0);   // 현재 expert 요금제 이상은 구현 x
        } else {
            setSelectedPlanId(Number(userAccessLevel));
        }
    }, [userAccessLevel]);

    const handleSwitchClick = () => {
        setIsMonthly(!isMonthly);
    }

    const handleCtaClick = (planId) => {
        if (planId) {
            const plan = usagePlans.find(plan => plan.id === planId);
            const newAmount = isMonthly ? plan.monthlyPrice * 10_000 : plan.yearlyPrice * 10_000;
            const newItemName = '젠투 ' + plan.name + (isMonthly ? ' 월 결제' : ' 연 결제');
            requestPayment(newAmount, paymentInfo.shopId, newItemName)
                .then(res => {
                    setPaymentInfo({...paymentInfo, amount: newAmount, itemName: newItemName, ...res});
                    setModalState({status: true, ctaLink: plan.ctaLink});
                })
        }
    }

    const handleCloseModal = () => {
        setModalState({status: false, ctaLink: ''});
        setPaymentStatus(null); // Reset payment status when closing modal
    }

    return (
        <>
        {   modalState.status && (
                <div className={plansStyle.CtaModal__Overlay} onClick={handleCloseModal}>
                    <div 
                        className={plansStyle.CtaModal__Content} 
                        onClick={e => e.stopPropagation()}
                    >
                        {
                            isProd && !isGentoo ?
                            <iframe 
                                src={modalState.ctaLink}
                                className={plansStyle.CtaModal__Iframe}
                                title="CTA Content"
                            /> :
                            <div className={plansStyle.CtaModal__Iframe}>
                            {
                                paymentStatus ? (
                                    paymentStatus.status === 'success' ? (
                                        <SuccessPage paymentInfo={paymentInfo} setPaymentInfo={setPaymentInfo} />
                                    ) : (
                                        <FailPage paymentInfo={paymentInfo} setPaymentInfo={setPaymentInfo} />
                                    )
                                )
                                :
                                <CheckoutPage paymentInfo={paymentInfo} setPaymentInfo={setPaymentInfo}/>
                            }
                            </div>
                        }
                    </div>
                </div>
            )
        }
        <div className={plansStyle.Plans__Container}>
            <div className={plansStyle.Plans__Plan}>
                <div className={plansStyle.Plans__PlanTitle}>
                    <p className='h2 fc-gray-700' style={{ textAlign: 'center' }}>요금제를 비교하고 선택해 보세요</p>
                    <div className='spacing-24' />
                    <div className={plansStyle.PlanToggle__Wrap}>
                        <div className={plansStyle.PlanToggle__Switch}>
                            <p className={`h6 ${isMonthly ? 'fc-gray-800' : 'fc-gray-600'}`}>월 결제</p>
                            <img 
                                src={isMonthly ? '/img/units/switch-active-off.png' : '/img/units/switch-active-on.png'} 
                                width={50} 
                                height={30} 
                                style={{cursor: 'pointer'}}
                                onClick={handleSwitchClick}
                                alt='toggle-switch'
                            />
                            <p className={`h6 ${isMonthly ? 'fc-gray-600' : 'fc-gray-800'}`}>연 결제</p>
                        </div>
                        <div className={plansStyle.PlanToggle__DiscountLabel} style={{ visibility: isMonthly ? 'hidden' : 'visible' }}>
                            <p className='h8 fc-prim-800'>-20%</p>
                        </div>
                    </div>
                </div>
                <div className='spacing-24' />
                <div className={plansStyle.Plans__PlanBody}>
                    <div className={plansStyle.PlanCards__Container}>
                        <div className={plansStyle.PlanCards__CateCard}>
                            <p className='h4 fc-gray-500'>요금 안내</p>
                        </div>
                        {
                            usagePlans.map((plan) => (
                                <div className={`${plansStyle.PlanCards__Card} ${plan.id === selectedPlanId ? plansStyle.selectedPlan__title : ''}`} key={plan.id}>
                                    <div className={plansStyle.PlanCards__PlanInfo__Wrap}>
                                        <div className={plansStyle.PlanCards__PlanInfo__Description}>
                                            <div className={plansStyle.PlanCards__PlanInfo__Name}>
                                                <p className={`h4 ${plan.id === selectedPlanId ? 'fc-gray-600' : 'fc-gray-500'}`}>{plan.name}</p>
                                                <img 
                                                    src={plan.id === selectedPlanId ? '/img/units/checkcircle-active-select.png' : '/img/units/checkcircle-active-unselect.png'} 
                                                    alt='check' 
                                                    width={24}
                                                    height={24}
                                                />
                                            </div>
                                            <p className={`h12 ${plan.id === selectedPlanId ? 'fc-gray-500' : 'fc-gray-400'}`}>{plan.description}</p>
                                        </div>
                                        <div className={plansStyle.PlanCards__PlanInfo__Details}>
                                            <div style={{display: 'flex', alignItems: 'flex-end'}}>
                                                <p className='h4 fc-gray-800'>{isMonthly ? plan.monthlyPrice + '만원' : plan.yearlyPrice + '만원'}</p>
                                                <p className='h9 fc-gray-800'>/월</p>
                                            </div>
                                            <p className='h9 fc-prim-800' style={{ visibility: isMonthly ? 'hidden' : 'visible' }}>-20% 할인</p>
                                        </div>
                                    </div>
                                    <div 
                                        className={`${plansStyle.PlanCards__PlanCta} ${((plan.id === selectedPlanId) && (planBillingType === (isMonthly ? 'MONTHLY' : 'ANNUAL'))) ? plansStyle.selectedPlan__cta : ''}`}
                                        onClick={() => handleCtaClick(((plan.id === selectedPlanId) && (planBillingType === (isMonthly ? 'MONTHLY' : 'ANNUAL'))) ? null : plan.id)}
                                    >
                                        <p className={((plan.id === selectedPlanId) && (planBillingType === (isMonthly ? 'MONTHLY' : 'ANNUAL'))) ? 'h6 fc-gray-500' : 'h6 fc-white'}>
                                            {((plan.id === selectedPlanId) && (planBillingType === (isMonthly ? 'MONTHLY' : 'ANNUAL'))) ? '사용중' : (isProd && !isGentoo ? '문의하기' : '결제하기')}
                                        </p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className={plansStyle.PlanTable__Container}>
                        {/* 데이터 테이블 */}
                        <div className={plansStyle.PlanTable__Table__Wrap}>
                            <div className={plansStyle.PlanTable__Table__Title}>
                                <p className='h6 fc-gray-600'>데이터</p>
                            </div>
                            {
                                dataSubTables.map((subTable) => (
                                    <div className={plansStyle.PlanTable__SubTable__Wrap} key={subTable.id}>
                                        <div className={subTable.id > 1 ? plansStyle.PlanTable__SubTable__Title__topBorder : plansStyle.PlanTable__SubTable__Title__default}>
                                            <p className='h6 fc-gray-700'>{subTable.subTableTitle}</p>
                                        </div>
                                        <div className={plansStyle.PlanTable__SubTable__Columns}>
                                            {
                                                subTable.subTableItems.map((item) => (
                                                    <div className={plansStyle.PlanTable__SubTable__Items} key={item.id}>
                                                        <div className={plansStyle.PlanTable__SubTable__Row}>
                                                            <p className='h7 fc-gray-600'>{item.subTableRow}</p>
                                                        </div>
                                                        {
                                                            item.itemValueIsText ? (
                                                                item.subTableItemList.map((i) => (
                                                                    <div className={`${plansStyle.PlanTable__SubTable__Item} ${i.id === selectedPlanId ? plansStyle.selectedPlan__item : ''}`} key={i.id}>
                                                                        <p className='h7 fc-gray-600'>{i.value}</p>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                item.subTableItemList.map((i) => (
                                                                    <div className={`${plansStyle.PlanTable__SubTable__Item} ${i.id === selectedPlanId ? plansStyle.selectedPlan__item : ''}`} key={i.id}>
                                                                        {
                                                                            i.value && 
                                                                            <img src='/img/units/check.png' alt='check' width={24} height={24} />}
                                                                    </div>
                                                                ))
                                                            )
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className='spacing-32'/>
                        {/* 콘솔 테이블 */}
                        <div className={plansStyle.PlanTable__Table__Wrap}>
                            <div className={plansStyle.PlanTable__Table__Title}>
                                <p className='h6 fc-gray-600'>콘솔</p>
                            </div>
                            {
                                consoleSubTables.map((subTable) => (
                                    <div className={plansStyle.PlanTable__SubTable__Wrap} key={subTable.id}>
                                        <div className={subTable.id > 1 ? plansStyle.PlanTable__SubTable__Title__topBorder : plansStyle.PlanTable__SubTable__Title__default}>
                                            <p className='h6 fc-gray-700'>{subTable.subTableTitle}</p>
                                        </div>
                                        <div className={plansStyle.PlanTable__SubTable__Columns}>
                                            {
                                                subTable.subTableItems.map((item) => (
                                                    <div className={plansStyle.PlanTable__SubTable__Items} key={item.id}>
                                                        <div className={plansStyle.PlanTable__SubTable__Row}>
                                                            <p className='h7 fc-gray-600'>{item.subTableRow}</p>
                                                        </div>
                                                        {
                                                            item.itemValueIsText ? (
                                                                item.subTableItemList.map((i) => (
                                                                    <div className={`${plansStyle.PlanTable__SubTable__Item} ${i.id === selectedPlanId ? plansStyle.selectedPlan__item : ''}`} key={i.id}>
                                                                        <p className='h7 fc-gray-600'>{i.value}</p>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                item.subTableItemList.map((i) => (
                                                                    <div className={`${plansStyle.PlanTable__SubTable__Item} ${i.id === selectedPlanId ? plansStyle.selectedPlan__item : ''}`} key={i.id}>
                                                                        {
                                                                            i.value && 
                                                                            <img src='/img/units/check.png' alt='check' width={24} height={24} />}
                                                                    </div>
                                                                ))
                                                            )
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ConsoleFooterSpacer />
            <ConsoleFooter />
        </div>
        </>
    )
}

export default Plans;