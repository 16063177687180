import axios from "axios";

export const requestPayment = async (amount, shopId, itemName) => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_PAYMENT_URL + '/request',
            {
                amount: amount,
                shopId: shopId,
                itemName: itemName,
            },
            {
                headers: {}
            }
        )
        return response.data;
    } catch (error) {
        console.error(`Error while calling requestPayment api: ${error}`);
    }
}

export const postPaymentConfirm = async (shopId, paymentKey, orderId, amount) => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_PAYMENT_URL + '/confirm',
            {
                shopId: shopId,
                paymentKey: paymentKey,
                orderId: orderId,
                amount: amount,
            },
            {
                headers: {}
            }
        )
        return response;
    } catch (error) {
        console.error(`Error while calling postPaymentConfirm api: ${error}`);
    }
}