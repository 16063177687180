
export const usagePlans = [
    {
        id: 1,
        name: 'Pro',
        description: '유저와 대화하며 맞춤 상품을 추천',
        monthlyPrice: 90,
        yearlyPrice: 72,
        discount: 20,
        ctaLink: 'https://g2vp5qyhsnt.typeform.com/to/BvdjFpLX',
    },
    {
        id: 2,
        name: 'Advanced',
        description: '유저 행동과 취향을 기억하여 초개인화',
        monthlyPrice: 180,
        yearlyPrice: 144,
        discount: 20,
        ctaLink: 'https://g2vp5qyhsnt.typeform.com/to/ilhrxNdB',
    },
    {
        id: 3,
        name: 'Expert',
        description: '복잡한 정책서와 옵션까지 구매 상담',
        monthlyPrice: 360,
        yearlyPrice: 288,
        discount: 20,
        ctaLink: 'https://g2vp5qyhsnt.typeform.com/to/H8dwMulP',
    },
]

export const dataSubTables = [
    {
        id: 1,
        subTableTitle: '상품 데이터 활용',
        subTableItems: [
            {
                id: 1,
                subTableRow: '기본 사용량 (대화 건수)',
                itemValueIsText: true,
                subTableItemList: [
                    {
                        id: 1,
                        value: '기본 제공 3만 건'
                    },
                    {
                        id: 2,
                        value: '기본 제공 3만 건'
                    },
                    {
                        id: 3,
                        value: '기본 제공 3만 건'
                    },
                ]
            },
            {
                id: 2,
                subTableRow: '상품 상세 정보 텍스트',
                itemValueIsText: true,
                subTableItemList: [
                    {
                        id: 1,
                        value: '5만 건까지'
                    },
                    {
                        id: 2,
                        value: '10만 건까지'
                    },
                    {
                        id: 3,
                        value: '10만 건까지'
                    },
                ]
            },
            {
                id: 3,
                subTableRow: '상품 이미지',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: true
                    },
                    {
                        id: 2,
                        value: true
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
            {
                id: 4,
                subTableRow: '상품 리뷰',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: true
                    },
                    {
                        id: 2,
                        value: true
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
            {
                id: 5,
                subTableRow: '구매 조건 및 상세 옵션',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: false
                    },
                    {
                        id: 2,
                        value: false
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
            {
                id: 6,
                subTableRow: '상품 데이터 업데이트',
                itemValueIsText: true,
                subTableItemList: [
                    {
                        id: 1,
                        value: '1회 / 일'
                    },
                    {
                        id: 2,
                        value: '1회 / 일'
                    },
                    {
                        id: 3,
                        value: '1회 / 일'
                    },
                ]
            }
        ]
    },
    {
        id: 2,
        subTableTitle: '특화 데이터 활용',
        subTableItems: [
            {
                id: 1,
                subTableRow: '커스텀 프롬프트 적용',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: true
                    },
                    {
                        id: 2,
                        value: true
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
            {
                id: 2,
                subTableRow: '   ⎿  기업소개 및 페르소나',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: true
                    },
                    {
                        id: 2,
                        value: true
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
            {
                id: 3,
                subTableRow: '   ⎿  상품 추천 대화 메뉴얼',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: true
                    },
                    {
                        id: 2,
                        value: true
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
            {
                id: 4,
                subTableRow: '   ⎿  CS 관련 대화 메뉴얼',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: true
                    },
                    {
                        id: 2,
                        value: true
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
            {
                id: 5,
                subTableRow: '실시간 인기 / 조회 / 판매량',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: true
                    },
                    {
                        id: 2,
                        value: true
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
        ]
    },
    {
        id: 3,
        subTableTitle: '유저 데이터 활용',
        subTableItems: [
            {
                id: 1,
                subTableRow: '유저 현재 세션 데이터',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: true
                    },
                    {
                        id: 2,
                        value: true
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
            {
                id: 2,
                subTableRow: '유저 구매 내역',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: false
                    },
                    {
                        id: 2,
                        value: true
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
            {
                id: 3,
                subTableRow: '유저 인적 정보',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: false
                    },
                    {
                        id: 2,
                        value: true
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
            {
                id: 4,
                subTableRow: '유저 방문 세션 기록',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: false
                    },
                    {
                        id: 2,
                        value: true
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
        ]
    },
    {
        id: 4,
        subTableTitle: '추가 데이터 활용',
        subTableItems: [
            {
                id: 1,
                subTableRow: '실시간 웹 검색',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: false
                    },
                    {
                        id: 2,
                        value: false
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
            {
                id: 2,
                subTableRow: '실시간 SNS 데이터',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: false
                    },
                    {
                        id: 2,
                        value: false
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
            {
                id: 3,
                subTableRow: '할인 및 쿠폰 정보',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: false
                    },
                    {
                        id: 2,
                        value: false
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
        ]
    },
    {
        id: 5,
        subTableTitle: '대화 설정',
        subTableItems: [
            {
                id: 1,
                subTableRow: '대화 내 맥락 기억',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: true
                    },
                    {
                        id: 2,
                        value: true
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
            {
                id: 2,
                subTableRow: '그리팅(시작 문구) 설정',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: true
                    },
                    {
                        id: 2,
                        value: true
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
            {
                id: 3,
                subTableRow: '예시 문구 설정',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: true
                    },
                    {
                        id: 2,
                        value: true
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
            {
                id: 4,
                subTableRow: '이상 대화 감지 및 차단',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: true
                    },
                    {
                        id: 2,
                        value: true
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
        ]
    }
]

export const consoleSubTables = [
    {
        id: 1,
        subTableTitle: '인터페이스 설정',
        subTableItems: [
            {
                id: 1,
                subTableRow: '채팅장 디자인 설정',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: true
                    },
                    {
                        id: 2,
                        value: true
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
            {
                id: 2,
                subTableRow: '플로팅 버튼 위치 및 문구 설정',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: true
                    },
                    {
                        id: 2,
                        value: true
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
        ]
    },
    {
        id: 2,
        subTableTitle: '대시보드 및 분석',
        subTableItems: [
            {
                id: 1,
                subTableRow: '성과 대시보드',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: true
                    },
                    {
                        id: 2,
                        value: true
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
            {
                id: 2,
                subTableRow: '대화 데이터 분석',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: true
                    },
                    {
                        id: 2,
                        value: true
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
            {
                id: 3,
                subTableRow: '대화 인사이트 리포트',
                itemValueIsText: false,
                subTableItemList: [
                    {
                        id: 1,
                        value: false
                    },
                    {
                        id: 2,
                        value: true
                    },
                    {
                        id: 3,
                        value: true
                    },
                ]
            },
        ]
    }
]

export const consoleItems = [
    {
        id: 1,
        row: '성과 대시보드',
        itemValueIsText: false,
        itemList: [
            {
                id: 1,
                value: true
            },
            {
                id: 2,
                value: true
            },
            {
                id: 3,
                value: true
            },
        ]
    },
    {
        id: 2,
        row: '사용자 대화 분석',
        itemValueIsText: false,
        itemList: [
            {
                id: 1,
                value: true
            },
            {
                id: 2,
                value: true
            },
            {
                id: 3,
                value: true
            },
        ]
    },
    {
        id: 3,
        row: '젠투 인터페이스 설정',
        itemValueIsText: false,
        itemList: [
            {
                id: 1,
                value: true
            },
            {
                id: 2,
                value: true
            },
            {
                id: 3,
                value: true
            },
        ]
    }
]