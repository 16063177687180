import { useEffect, useState } from "react";
import { validateInput } from '../../apis/services/utils';
import signupStyle from '../../style/login.module.css';

export const InputField = (props) => {
    const {name, type, labelText, placeholderText, callbackFunc = () => {}, storeFunc = false, password = null, subText = '', subFunc = () => {}, validation = true} = props;
    const [valRes, setValRes] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [visibility, setVisibility] = useState(false);

    const handleInputChange = (e) => {
        const res = validateInput(name, e.currentTarget.value, password);
        setInputValue(e.currentTarget.value);
        if (validation) setValRes(res);
        if (storeFunc) storeFunc(e.currentTarget.value);
        if (callbackFunc && validation) callbackFunc(res);
    }

    useEffect(() => {
        setInputValue('');
        setValRes('');
        callbackFunc('');
    }, [name])

    return (
        <>
            {
                subText.length === 0 ?
                <label htmlFor={name} className="h11 fc-gray-600">
                        {labelText}
                </label> :
                <div className={signupStyle.InputField__LabelWrap}>
                    <label htmlFor={name} className="h11 fc-gray-600">
                        {labelText}
                    </label>
                    <button
                        className={signupStyle.InputField__SubFunc} 
                        onClick={subFunc}
                    >
                        <p className="h12 fc-prim-800">{subText}</p>
                    </button>
                </div>
            }
            <div className="spacing-6" />
            {
                (name === 'password' || name === 'passwordConfirm') ?
                <div className={signupStyle.InputField__PwInputWrap}>
                    <input 
                        id={name} name={name} type={visibility ? 'text' : 'password'} placeholder={placeholderText}
                        className={valRes || valRes === '' ? signupStyle.Signup__Input : signupStyle.Signup__Input__error}
                        onChange={(e) => handleInputChange(e)}
                        onKeyDown={(e) => {if(!validation) callbackFunc(e.key)}}
                        value={inputValue}
                    />
                    <img 
                        src={visibility ? '/img/units/visibility-visible.png' : '/img/units/visibility-not-visible.png'} 
                        className={signupStyle.InputField__VisibilityIcon}
                        onClick={() => setVisibility(!visibility)}
                        alt='visibility'
                    />
                </div> :
                <input 
                    id={name} name={name} type={type} placeholder={placeholderText}
                    className={valRes || valRes === '' ? signupStyle.Signup__Input : signupStyle.Signup__Input__error}
                    onChange={(e) => handleInputChange(e)}
                    onKeyDown={(e) => {if(!validation) callbackFunc(e.key)}}
                    value={inputValue}
                />
            }
            {
                (valRes !== '' && !valRes) &&
                getErrorMessages(name)
            }
        </>
    )
}

export const CopyField = (props) => {

    const {name, type, labelText, value, subText = '', subFunc = () => {}, copyIcon = true, handleCopy = () => {}} = props;

    return (
        <>
            {
                subText.length === 0 ?
                <label htmlFor={name} className="h11 fc-gray-600">
                        {labelText}
                </label> :
                <div className={signupStyle.InputField__LabelWrap}>
                    <label htmlFor={name} className="h11 fc-gray-600">
                        {labelText}
                    </label>
                    <button
                        className={signupStyle.InputField__SubFunc} 
                        onClick={subFunc}
                    >
                        <p className="h12 fc-prim-800">{subText}</p>
                    </button>
                </div>
            }
            <div className="spacing-6" />
            <div className={signupStyle.InputField__CopyField}>
                <p className="h7 fc-gray-800 default-cursor">{value}</p>
                {copyIcon && <img src='/img/units/copy.png' width={20} height={20} className="pointer" onClick={handleCopy} />}
            </div>
        </>
    )
}

const getErrorMessages = (type) => {
    let message;
    switch (type) {
        case 'email':
            message = '올바른 형식의 이메일 주소를 입력해 주세요';
            break;
        case 'password':
            message = '8~16자 이내 영문, 숫자, 특수문자만 사용 가능합니다';
            break;
        case 'passwordConfirm':
            message = '비밀번호가 일치하지 않습니다';
            break;
        default:
            return;
    }

    return (
        <div className={signupStyle.InputField__ErrorWrap}>
            <img src='/img/units/input-error.png' width={13} height={13} />
            <div className="spacing-4" />
            <p className="h13 fc-warning">{message}</p>
        </div>
    )
}