import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "../../style/plans.module.css";
import { postPaymentConfirm } from "../../apis/api/payment/api";

export const SuccessPage = ({paymentInfo, setPaymentInfo}) => {
    const [isConfirmed, setIsConfirmed] = useState(false);
    const paymentKey = paymentInfo.paymentKey;
    const orderId = paymentInfo.orderId;
    const amount = paymentInfo.amount;
    const setPaymentStatus = paymentInfo.setPaymentStatus;

    const confirmPayment = async () => {
        // TODO: API를 호출해서 서버에게 paymentKey, orderId, amount를 넘겨주세요.
        // 서버에선 해당 데이터를 가지고 승인 API를 호출하면 결제가 완료됩니다.
        // https://docs.tosspayments.com/reference#%EA%B2%B0%EC%A0%9C-%EC%8A%B9%EC%9D%B8
        postPaymentConfirm(paymentInfo.shopId, paymentKey, orderId, amount)
            .then(res => {
                if (res.status === 200) {
                    setIsConfirmed(true);
                } else {
                    setPaymentStatus({ status: 'fail', params: res });
                }
            })
            .catch(err => {
                setPaymentStatus({ status: 'fail', params: err });
            })
    }

    return (
        <div className={styles.Checkout__Wrap}>
        {
            isConfirmed ? (
                <div className={styles.Success__Confirmed__Wrap}>
                    <img
                        src="https://static.toss.im/illusts/check-blue-spot-ending-frame.png"
                        width="120"
                        height="120"
                    />
                    <h2 className={styles.Checkout__Title}>결제를 완료했어요</h2>
                    <div className={styles.Checkout__ResponseSection}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span className={styles.Checkout__ResponseLabel}>결제 금액</span>
                            <span id="amount" className={styles.Checkout__ResponseText}>
                                {amount}
                            </span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span className={styles.Checkout__ResponseLabel}>주문번호</span>
                            <span id="orderId" className={styles.Checkout__ResponseText}>
                                {orderId}
                            </span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span className={styles.Checkout__ResponseLabel}>paymentKey</span>
                            <span id="paymentKey" className={styles.Checkout__ResponseText}>
                                {paymentKey}
                            </span>
                        </div>
                    </div>
                    <div className={styles.Checkout__ButtonGroup}>
                        <div style={{ display: "flex", gap: "16px" }}>
                            <a
                                className={styles.Checkout__Btn}
                                href="https://developers.tosspayments.com/sandbox"
                                style = {{ textDecoration: "none", textAlign: "center" }}
                            >
                                다시 테스트하기
                            </a>
                            <a
                                className={styles.Checkout__Btn}
                                href="https://docs.tosspayments.com/guides/v2/payment-widget/integration"
                                target="_blank"
                                rel="noopener noreferrer"
                                style = {{ textDecoration: "none", textAlign: "center" }}
                            >
                                결제 연동 문서가기
                            </a>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.Success__Loading__Wrap}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <img
                            src="https://static.toss.im/lotties/loading-spot-apng.png"
                            width="120"
                            height="120"
                        />
                        <h2 className={styles.Checkout__Title} style={{ textAlign: "center" }}>결제 요청까지 성공했어요.</h2>
                        <h4 className={styles.Checkout__Description} style={{ textAlign: "center" }}>결제 승인하고 완료해보세요.</h4>
                    </div>
                    <div style={{ width: "100%" }}>
                        <button className={styles.Checkout__Btn__primary} onClick={confirmPayment}>
                            결제 승인하기
                        </button>
                    </div>
                </div>
            )
        }
        </div>
    );
}