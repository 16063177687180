import style from '../../style/units.module.css';

export const ConsoleFooter = () => {
    return (
        <div className={style.ConsoleFooter__Container}>
            <div className='spacing-32'/>
            <p className='h13 fc-gray-500'>사업자등록번호 701-87-01364 | 통신판매신고 2019-대전유성-1152 | 주식회사 와들 | 대표 : 박지혁</p>
            <p className='h13 fc-gray-500'>서울특별시 강남구 역삼로 172, 10층 C호 (역삼동, 마루360) | 042-716-0147</p>
        </div>
    )
}

export const ConsoleFooterSpacer = () => {
    return (
        <div className={style.ConsoleFooter__Spacer} />
    )
}