import buttonStyle from '../../style/units.module.css';

export const DefaultButton = (props) => {
    const {buttonText, disabled, onClick, hasIcon, iconSrc, iconWidth, iconHeight, disabledTextClassName, activeTextClassName} = props;
    return (
        disabled ?
        <button 
            className={buttonStyle.DefaultButton__Disabled}
            disabled={true}
        >
            <p className={disabledTextClassName}>{buttonText}</p>
            {hasIcon && (
                <>
                    <div className='spacing-4'/>
                    <img src={iconSrc} width={iconWidth} height={iconHeight} alt='' style={{filter: 'invert(50%) sepia(89%) saturate(0%) hue-rotate(203deg) brightness(98%) contrast(80%)'}}/>
                </>
            )}
        </button> :
        <button 
            className={buttonStyle.DefaultButton__Active}
            onClick={onClick}
        >
            <p className={activeTextClassName}>{buttonText}</p>
            {hasIcon && (
                <>
                    <div className='spacing-4'/>
                    <img src={iconSrc} width={iconWidth} height={iconHeight} alt=''/>
                </>
            )}
        </button>
    )
}