import crsStyle from '../../style/units.module.css';

export const Carousel = (props) => {
    const { type } = props;
    const mapArr = new Array(type === 'horizontal' ? 3 : 1).fill(0);
    return (
        <div className={crsStyle.Carousel__Container}>
            {
                type === 'single' ? <CarouselSingle /> :
                type === 'carousel-horizontal' ? <CarouselListHorizontal /> :
                type === 'carousel-vertical' ? <CarouselListVertical /> :
                <CarouselSingle />
            }
        </div>
    )
}

export const CarouselSingle = () => {
    return (
        <div className={crsStyle.Carousel__Wrap}>
            <div className={crsStyle.Carousel__ImageWrap}>
                <img src='/img/units/carousel-single-image.png' width='100%' height='100%' />
            </div>
            <div className='spacing-16' />
            <div className={crsStyle.Carousel__ContentsWrap}>
                <div className='spacing-20'/>
                <p className='h10 fc-gray-800'>A 상품명 텍스트 상품명 텍스트 상품명 텍스트 상품명 텍스트</p>
                <div style={{ height: '5px' }} />
                <div style={{ display: 'flex' }}>
                    <span className='h8 fc-warning'>26%</span>
                    <div className='spacing-2' />
                    <span className='h8 fc-gray-800'>26,800원</span>
                </div>
                <div className='spacing-4' />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src='/img/units/carousel-star-gray.png' width={14} height={14} />
                    <div className='spacing-2' />
                    <span className='h13 fc-gray-500'>4.9</span>
                    <div className='spacing-4' />
                    <span className='h13 fc-gray-500'>리뷰 14</span>
                </div>
                <div className='spacing-4' />
                <div className={crsStyle.Carousel__SingleButton}>
                    <p className='h12 fc-gray-600'>자세히 보기</p>
                </div>
            </div>
        </div>
    )
}

export const CarouselListHorizontal = () => {
    return (
        <div className={crsStyle.Carousel__Horizontal__Wrap}>
            <div className={crsStyle.Carousel__Horizontal__ItemWrap}>
                <div className={crsStyle.Carousel__Horizontal__ImageWrap}>
                    <img src='/img/units/carousel-list-image1.png' width={34} height={34} />
                </div>
                <div className='spacing-12' />
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p className='h13 fc-gray-800'>A 상품명 예시 텍스트 예시 텍스트 예시 텍스트</p>
                    </div>
                    <div className='spacing-4' />
                    <div style={{ display: 'flex' }}>
                        <span className='h12 fc-warning'>26%</span>
                        <div className='spacing-2' />
                        <span className='h12 fc-gray-800'>26,800원</span>
                        <div className='spacing-8' />
                        <img src='/img/units/carousel-star-gray.png' width={14} height={14} />
                        <div className='spacing-2' />
                        <span className='h13 fc-gray-500'>4.9</span>
                        <div className='spacing-4' />
                        <span className='h13 fc-gray-500'>리뷰 14</span>
                    </div>
                </div>
            </div>
            <div className={crsStyle.Carousel__Horizontal__ItemWrap}>
                <div className={crsStyle.Carousel__Horizontal__ImageWrap}>
                    <img src='/img/units/carousel-list-image2.png' width={34} height={34} />
                </div>
                <div className='spacing-12' />
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p className='h13 fc-gray-800'>B 상품명 예시 텍스트</p>
                    </div>
                    <div className='spacing-4' />
                    <div style={{ display: 'flex' }}>
                        <span className='h12 fc-gray-800'>15,300원</span>
                        <div className='spacing-8' />
                        <img src='/img/units/carousel-star-gray.png' width={14} height={14} />
                        <div className='spacing-2' />
                        <span className='h13 fc-gray-500'>4.2</span>
                        <div className='spacing-4' />
                        <span className='h13 fc-gray-500'>리뷰 226</span>
                    </div>
                </div>
            </div>
            <div className={crsStyle.Carousel__Horizontal__ItemWrap}>
                <div className={crsStyle.Carousel__Horizontal__ImageWrap}>
                    <img src='/img/units/carousel-list-image3.png' width={34} height={34} />
                </div>
                <div className='spacing-12' />
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p className='h13 fc-gray-800'>C 상품명 예시 텍스트 예시 텍스트</p>
                    </div>
                    <div className='spacing-4' />
                    <div style={{ display: 'flex' }}>
                        <span className='h12 fc-warning'>26%</span>
                        <div className='spacing-2' />
                        <span className='h12 fc-gray-800'>8,100원</span>
                        <div className='spacing-8' />
                        <img src='/img/units/carousel-star-gray.png' width={14} height={14} />
                        <div className='spacing-2' />
                        <span className='h13 fc-gray-500'>4.7</span>
                        <div className='spacing-4' />
                        <span className='h13 fc-gray-500'>리뷰 85</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const CarouselListVertical = () => {
    return (
        <div className={crsStyle.Carousel__Vertical__Wrap}>
            <div className={crsStyle.Carousel__Vertical__ItemWrap}>
                <div className={crsStyle.Carousel__Vertical__ImageWrap}>
                    <img src='/img/units/carousel-list-image1-large.png' width={109} height={91} />
                </div>
                <div className='spacing-2'/>
                <div className={crsStyle.Carousel__Vertical__ContentsWrap}>
                    <div className={crsStyle.Carousel__Vertical__NameWrap}>
                        <p className='h13 fc-gray-800'>A 상품명 텍스트 상품명 텍스트 상품명 텍스트 상품명 텍스트</p>
                    </div>
                    <div className='spacing-2' />
                    <div style={{ display: 'flex' }}>
                        <span className='h12 fc-warning'>26%</span>
                        <div className='spacing-2' />
                        <span className='h12 fc-gray-800'>26,800원</span>
                    </div>
                </div>
            </div>
            <div className={crsStyle.Carousel__Vertical__ItemWrap}>
                <div className={crsStyle.Carousel__Vertical__ImageWrap}>
                    <img src='/img/units/carousel-list-image2-large.png' width={109} height={91} />
                </div>
                <div className='spacing-2'/>
                <div className={crsStyle.Carousel__Vertical__ContentsWrap}>
                    <div className={crsStyle.Carousel__Vertical__NameWrap}>
                        <p className='h13 fc-gray-800'>B 상품명 예시 텍스트 예시 텍스트</p>
                    </div>
                    <div className='spacing-2' />
                    <div style={{ display: 'flex' }}>
                        <span className='h12 fc-gray-800'>15,300원</span>
                    </div>
                </div>
            </div>
            <div className={crsStyle.Carousel__Vertical__ItemWrap}>
                <div className={crsStyle.Carousel__Vertical__ImageWrap}>
                    <img src='/img/units/carousel-list-image3-large.png' width={109} height={91} />
                </div>
                <div className='spacing-2'/>
                <div className={crsStyle.Carousel__Vertical__ContentsWrap}>
                    <div className={crsStyle.Carousel__Vertical__NameWrap}>
                        <p className='h13 fc-gray-800'>C 상품명 예시 텍스트 예시 텍스트</p>
                    </div>
                    <div className='spacing-2' />
                    <div style={{ display: 'flex' }}>
                    <span className='h12 fc-warning'>26%</span>
                    <div className='spacing-2' />
                        <span className='h12 fc-gray-800'>8,100원</span>
                    </div>
                </div>
            </div>
        </div>
    )
}