import Chart from 'react-apexcharts'
import React, { useState, useEffect } from 'react'
import {CustomTooltip} from '../Units/CustomTooltip';
import { DBBarGraphLOption, DBLineGraphLOption, graphColors, createDBLineSmallOptions, formatStartEndDate, formatValue, DBLineGraphS_CustomTooltip, DBLineGraphL_CustomTooltip, DBBarGraphL_CustomTooltip } from '../../data/chartData';
import dbChartsStyle from '../../style/dashboardCharts.module.css';
import '../../style/apexcharts.css'
import { getFormattedNumberValue, getFormattedNumberWithSuffix } from '../../apis/api/utils';

export const SalesChart = (props) => {
    const { isLoading, noData, data, dbLineGraphSIdx, startDate, endDate } = props;
    const [graphValue, setGraphValue] = useState(null);
    const formattedStartDate = formatStartEndDate(startDate);
    const formattedEndDate = formatStartEndDate(endDate);
    const [series, setSeries] = useState(null);

    const salesChartOptions = createDBLineSmallOptions({
        colors: [graphColors.primary_800],
        tooltip: {
            enabled: true,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                const formattedDate = w.config.series[seriesIndex].data[dataPointIndex].x;
                const formattedValue = formatValue(w, seriesIndex, dataPointIndex);

                return DBLineGraphS_CustomTooltip(w, seriesIndex, formattedDate, formattedValue, graphColors.primary_700, '₩', 'prefix');
              },
        },
    });

    useEffect(() => {
        if (data && Array.isArray(data[dbLineGraphSIdx].series.data)) {
            setGraphValue((_) => {
                const newVal = data[dbLineGraphSIdx].textData.value;
                if (newVal === null) return null;
                const formattedNewVal = getFormattedNumberValue(newVal);
                return formattedNewVal;
            });
            setSeries([{...data[dbLineGraphSIdx].series, name: '결제액'}])
        }
    }, [data])

    return (
        <div className={dbChartsStyle.DBLineGraphS__Container}>
            <div className={dbChartsStyle.DBLineGraphS__TextWrap}>
                <div className={dbChartsStyle.DBLineGraphS__Category}>
                    <p className='h6 fc-gray-600 w-fit-content'>결제액</p>
                    <div className='spacing-4' />
                    <CustomTooltip tipText={"젠투 추천 72시간 내, 결제취소 미포함"} />
                </div>
                <div className='spacing-8' />
                {
                    isLoading ? (
                        <img src='/img/units/loading-ani-S.gif' width={105} height={30}/>
                    ) : (
                            <p className='h3 fc-gray-800'>
                                {
                                    (noData || !series || !series[0]?.data?.length) ? `No data` :
                                        (data[dbLineGraphSIdx]?.textData.unitPosition === 'prefix' 
                                        ? `${data[dbLineGraphSIdx]?.textData.unit}${graphValue}` 
                                        : `${graphValue}${data[dbLineGraphSIdx]?.textData.unit}`)
                                }
                            </p>
                    )
                }
            </div>
            <div className={dbChartsStyle.DBLineGraphS__GraphWrap}>
                {
                    isLoading ? (
                        <div className={dbChartsStyle.DBLineGraphS__NoChartWrap}>
                            <img src='/img/units/loading-ani-L.gif' width={261} height={121} style={{ objectFit: 'cover' }}/>
                        </div>
                    ) : (
                            (noData || !series || !series[0]?.data?.length) ? ( 
                                <div className={dbChartsStyle.DBLineGraphS__NoChartWrap}>
                                    <p className='h6 fc-gray-600'>no data</p>
                                </div>
                            ) :
                            <>
                                <div className={dbChartsStyle.DBLineGraphS__ChartWrap}>
                                    <Chart 
                                        key={JSON.stringify(salesChartOptions)} 
                                        options={salesChartOptions} 
                                        series={series} 
                                        type="area" 
                                        height='140px' 
                                        width='100%' 
                                    />
                                </div>
                                <div className='spacing-16'/>
                                <hr className={dbChartsStyle.DBLineGraphS__XAxis}/>
                                <div className='spacing-8'/>
                                <div className={dbChartsStyle.DBLineGraphS__Dates}>
                                    <p className='h10 fc-gray-400 default-cursor'>{formattedStartDate}</p>
                                    <p className='h10 fc-gray-400 default-cursor'>{formattedEndDate}</p>
                                </div>
                            </>
                    )
                }
            </div>
        </div>
    );
}

export const UsersChart = (props) => {
    const { isLoading, noData, data, dbLineGraphSIdx, startDate, endDate } = props;
    const [graphValue, setGraphValue] = useState(null);
    const formattedStartDate = formatStartEndDate(startDate);
    const formattedEndDate = formatStartEndDate(endDate);
    const [series, setSeries] = useState(null);

    const usersChartOptions = createDBLineSmallOptions({
        colors: [graphColors.purple_800],
        tooltip: {
            enabled: true,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                const formattedDate = w.config.series[seriesIndex].data[dataPointIndex].x;
                const formattedValue = formatValue(w, seriesIndex, dataPointIndex);

                return DBLineGraphS_CustomTooltip(w, seriesIndex, formattedDate, formattedValue, graphColors.purple_800, '', '');
              }
        }
    });

    useEffect(() => {
        if (data && Array.isArray(data[dbLineGraphSIdx].series.data)) {
            setGraphValue((_) => {
                const newVal = data[dbLineGraphSIdx].textData.value;
                if (newVal === null) return null;
                const formattedNewVal = getFormattedNumberValue(newVal);
                return formattedNewVal;
            });
            setSeries([{...data[dbLineGraphSIdx].series, name: '구매자 수'}])
        }
    }, [data])

    return (
        <div className={dbChartsStyle.DBLineGraphS__Container}>
            <div className={dbChartsStyle.DBLineGraphS__TextWrap}>
                <div className={dbChartsStyle.DBLineGraphS__Category}>
                    <p className='h6 fc-gray-600 w-fit-content'>구매자 수</p>
                    <div className='spacing-4'/>
                    <CustomTooltip tipText={"젠투 추천 72시간 내, 유저 토큰 기준"} />
                </div>
                <div className='spacing-8' />
                {
                    isLoading ? (
                        <img src='/img/units/loading-ani-S.gif' width={105} height={30}/>
                    ) : (
                            <p className='h3 fc-gray-800'>
                                {
                                    (noData || !series || !series[0]?.data?.length) ? `No data` :
                                        (data[dbLineGraphSIdx]?.textData.unitPosition === 'prefix' 
                                        ? `${data[dbLineGraphSIdx]?.textData.unit}${graphValue}` 
                                        : `${graphValue}${data[dbLineGraphSIdx]?.textData.unit}`)
                                }
                            </p>
                    )
                }
            </div>
            <div className={dbChartsStyle.DBLineGraphS__GraphWrap}>
                {
                    isLoading ? (
                        <div className={dbChartsStyle.DBLineGraphS__NoChartWrap}>
                            <img src='/img/units/loading-ani-L.gif' width={261} height={121} style={{ objectFit: 'cover' }}/>
                        </div>
                    ) : (
                            (noData || !series || !series[0]?.data?.length) ? ( 
                                <div className={dbChartsStyle.DBLineGraphS__NoChartWrap}>
                                    <p className='h6 fc-gray-600'>no data</p>
                                </div>
                            ) :
                            <>
                                <div className={dbChartsStyle.DBLineGraphS__ChartWrap}>
                                    <Chart 
                                        options={usersChartOptions} 
                                        series={series} 
                                        type="area" 
                                        height='140px' 
                                    />
                                </div>
                                <div className='spacing-16'/>
                                <hr className={dbChartsStyle.DBLineGraphS__XAxis}/>
                                <div className='spacing-8'/>
                                <div className={dbChartsStyle.DBLineGraphS__Dates}>
                                    <p className='h10 fc-gray-400 default-cursor'>{formattedStartDate}</p>
                                    <p className='h10 fc-gray-400 default-cursor'>{formattedEndDate}</p>
                                </div>
                            </>
                    )
                }
            </div>
        </div>
    );
}

export const ConversionRateChart = (props) => {
    const { isLoading, noData, data, dbLineGraphSIdx, startDate, endDate } = props;
    const [graphValue, setGraphValue] = useState(null);
    const formattedStartDate = formatStartEndDate(startDate);
    const formattedEndDate = formatStartEndDate(endDate);
    const [series, setSeries] = useState(null);

    const conversionRateChartOptions = createDBLineSmallOptions({
        colors: [graphColors.skyblue_900],
        tooltip: {
            enabled: true,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                const formattedDate = w.config.series[seriesIndex].data[dataPointIndex].x;
                const formattedValue = formatValue(w, seriesIndex, dataPointIndex);

                return DBLineGraphS_CustomTooltip(w, seriesIndex, formattedDate, formattedValue, graphColors.skyblue_900, '%', 'suffix');
              }
        }
    });

    useEffect(() => {
        if (data && Array.isArray(data[dbLineGraphSIdx].series.data)) {
            setGraphValue((_) => {
                const newVal = data[dbLineGraphSIdx].textData.value;
                if (newVal === null) return null;
                const formattedNewVal = getFormattedNumberValue(newVal);
                return formattedNewVal;
            });
            setSeries([{...data[dbLineGraphSIdx].series, name: '노출 대비 구매 전환율'}])
        }
    }, [data])

    return (
        <div className={dbChartsStyle.DBLineGraphS__Container}>
            <div className={dbChartsStyle.DBLineGraphS__TextWrap}>
                <div className={dbChartsStyle.DBLineGraphS__Category}>
                    <p className='h6 fc-gray-600 w-fit-content'>노출 대비 구매 전환율</p>
                    <div className='spacing-4'/>
                    <CustomTooltip tipText={"젠투 버튼 노출 횟수 대비 구매 건수"} />
                </div>
                <div className='spacing-8' />
                {
                    isLoading ? (
                        <img src='/img/units/loading-ani-S.gif' width={105} height={30}/>
                    ) : (
                            <p className='h3 fc-gray-800'>
                                {
                                    (noData || !series || !series[0]?.data?.length) ? `No data` :
                                        (data[dbLineGraphSIdx]?.textData.unitPosition === 'prefix' 
                                        ? `${data[dbLineGraphSIdx]?.textData.unit}${graphValue}` 
                                        : `${graphValue}${data[dbLineGraphSIdx]?.textData.unit}`)
                                }
                            </p>
                    )
                }
            </div>
            <div className={dbChartsStyle.DBLineGraphS__GraphWrap}>
                {
                    isLoading ? (
                        <div className={dbChartsStyle.DBLineGraphS__NoChartWrap}>
                            <img src='/img/units/loading-ani-L.gif' width={261} height={121} style={{ objectFit: 'cover' }}/>
                        </div>
                    ) : (
                            (noData || !series || !series[0]?.data?.length) ? ( 
                                <div className={dbChartsStyle.DBLineGraphS__NoChartWrap}>
                                    <p className='h6 fc-gray-600'>no data</p>
                                </div>
                            ) :
                            <>
                                <div className={dbChartsStyle.DBLineGraphS__ChartWrap}>
                                    <Chart 
                                        options={conversionRateChartOptions} 
                                        series={series} 
                                        type="area" 
                                        height='140px' 
                                    />
                                </div>
                                <div className='spacing-16'/>
                                <hr className={dbChartsStyle.DBLineGraphS__XAxis}/>
                                <div className='spacing-8'/>
                                <div className={dbChartsStyle.DBLineGraphS__Dates}>
                                    <p className='h10 fc-gray-400 default-cursor'>{formattedStartDate}</p>
                                    <p className='h10 fc-gray-400 default-cursor'>{formattedEndDate}</p>
                                </div>
                            </>
                    )
                }
            </div>
        </div>
    );
}


export const ConversationChart = (props) => {
    const { isLoading, noData, data, dbLineGraphSIdx, startDate, endDate } = props;
    const [graphValue, setGraphValue] = useState(null);
    const formattedStartDate = formatStartEndDate(startDate);
    const formattedEndDate = formatStartEndDate(endDate);
    const [series, setSeries] = useState(null);

    const conversationChartOptions = createDBLineSmallOptions({
        colors: [graphColors.primary_900],
        tooltip: {
            enabled: true,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                const formattedDate = w.config.series[seriesIndex].data[dataPointIndex].x;
                const formattedValue = formatValue(w, seriesIndex, dataPointIndex);

                return DBLineGraphS_CustomTooltip(w, seriesIndex, formattedDate, formattedValue, graphColors.primary_900, '', '');
              },
        }
    });

    useEffect(() => {
        if (data && Array.isArray(data[dbLineGraphSIdx].series.data)) {
            setGraphValue((_) => {
                const newVal = data[dbLineGraphSIdx].textData.value;
                if (newVal === null) return null;
                const formattedNewVal = getFormattedNumberValue(newVal);
                return formattedNewVal;
            });
            setSeries([{...data[dbLineGraphSIdx].series, name: '대화쌍 수'}])
        }
    }, [data])

    return (
            <div className={dbChartsStyle.DBLineGraphS__Container}>
                <div className={dbChartsStyle.DBLineGraphS__TextWrap}>
                    <div className={dbChartsStyle.DBLineGraphS__Category}>
                        <p className='h6 fc-gray-600 w-fit-content'>대화쌍 수</p>
                        <div className='spacing-4'/>
                        <CustomTooltip tipText={"젠투와 대화를 주고받은 총 횟수"} />
                    </div>
                    <div className='spacing-8' />
                    {
                        isLoading ? (
                            <img src='/img/units/loading-ani-S.gif' width={105} height={30}/>
                        ) : (
                                <p className='h3 fc-gray-800'>
                                    {
                                        (noData || !series || !series[0]?.data?.length) ? `No data` :
                                            (data[dbLineGraphSIdx]?.textData.unitPosition === 'prefix' 
                                            ? `${data[dbLineGraphSIdx]?.textData.unit}${graphValue}` 
                                            : `${graphValue}${data[dbLineGraphSIdx]?.textData.unit}`)
                                    }
                                </p>
                        )
                    }
                </div>
                <div className={dbChartsStyle.DBLineGraphS__GraphWrap}>
                    {
                        isLoading ? (
                            <div className={dbChartsStyle.DBLineGraphS__NoChartWrap}>
                                <img src='/img/units/loading-ani-L.gif' width={261} height={121} style={{ objectFit: 'cover' }}/>
                            </div>
                        ) : (
                                (noData || !series || !series[0]?.data?.length) ? ( 
                                    <div className={dbChartsStyle.DBLineGraphS__NoChartWrap}>
                                        <p className='h6 fc-gray-600'>no data</p>
                                    </div>
                                ) :
                                <>
                                    <div className={dbChartsStyle.DBLineGraphS__ChartWrap}>
                                        <Chart 
                                            options={conversationChartOptions} 
                                            series={series} 
                                            type="area" 
                                            height='140px' 
                                        />
                                    </div>
                                    <div className='spacing-16'/>
                                    <hr className={dbChartsStyle.DBLineGraphS__XAxis}/>
                                    <div className='spacing-8'/>
                                    <div className={dbChartsStyle.DBLineGraphS__Dates}>
                                        <p className='h10 fc-gray-400 default-cursor'>{formattedStartDate}</p>
                                        <p className='h10 fc-gray-400 default-cursor'>{formattedEndDate}</p>
                                    </div>
                                </>
                        )
                    }
                </div>
            </div>
    );
}

export const ARPPUChart = (props) => {
    const { isLoading, noData, data, dbLineGraphSIdx, startDate, endDate } = props;
    const [graphValue, setGraphValue] = useState(null);
    const formattedStartDate = formatStartEndDate(startDate);
    const formattedEndDate = formatStartEndDate(endDate);
    const [series, setSeries] = useState(null);

    const ARPPUChartOptions = createDBLineSmallOptions({
        colors: [graphColors.tealgreen_700],
        tooltip: {
            enabled: true,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                const formattedDate = w.config.series[seriesIndex].data[dataPointIndex].x;
                const formattedValue = formatValue(w, seriesIndex, dataPointIndex);

                return DBLineGraphS_CustomTooltip(w, seriesIndex, formattedDate, formattedValue, graphColors.tealgreen_700, '₩', 'prefix');
              },
        }
    });

    useEffect(() => {
        if (data && Array.isArray(data[dbLineGraphSIdx].series.data)) {
            setGraphValue((_) => {
                const newVal = data[dbLineGraphSIdx].textData.value;
                if (newVal === null) return null;
                const formattedDecimalVal = Math.floor(newVal);
                const formattedNewVal = getFormattedNumberValue(formattedDecimalVal);
                return formattedNewVal;
            });
            setSeries([{...data[dbLineGraphSIdx].series, name: 'ARPPU'}])
        }
    }, [data])

    return (
        <div className={dbChartsStyle.DBLineGraphS__Container}>
            <div className={dbChartsStyle.DBLineGraphS__TextWrap}>
                <div className={dbChartsStyle.DBLineGraphS__Category}>
                    <p className='h6 fc-gray-600 w-fit-content'>ARPPU</p>
                    <div className='spacing-4'/>
                    <CustomTooltip tipText={"결제 고객 당 평균 객단가"} />
                </div>
                <div className='spacing-8' />
                {
                    isLoading ? (
                        <img src='/img/units/loading-ani-S.gif' width={105} height={30}/>
                    ) : (
                            <p className='h3 fc-gray-800'>
                                {
                                    (noData || !series || !series[0]?.data?.length) ? `No data` :
                                        (data[dbLineGraphSIdx]?.textData.unitPosition === 'prefix' 
                                        ? `${data[dbLineGraphSIdx]?.textData.unit}${graphValue}` 
                                        : `${graphValue}${data[dbLineGraphSIdx]?.textData.unit}`)
                                }
                            </p>
                    )
                }
            </div>
            <div className={dbChartsStyle.DBLineGraphS__GraphWrap}>
                {
                    isLoading ? (
                        <div className={dbChartsStyle.DBLineGraphS__NoChartWrap}>
                            <img src='/img/units/loading-ani-L.gif' width={261} height={121} style={{ objectFit: 'cover' }}/>
                        </div>
                    ) : (
                            (noData || !series || !series[0]?.data?.length) ? ( 
                                <div className={dbChartsStyle.DBLineGraphS__NoChartWrap}>
                                    <p className='h6 fc-gray-600'>no data</p>
                                </div>
                            ) :
                            <>
                                <div className={dbChartsStyle.DBLineGraphS__ChartWrap}>
                                    <Chart 
                                        options={ARPPUChartOptions} 
                                        series={series} 
                                        type="area" 
                                        height='140px' 
                                    />
                                </div>
                                <div className='spacing-16'/>
                                <hr className={dbChartsStyle.DBLineGraphS__XAxis}/>
                                <div className='spacing-8'/>
                                <div className={dbChartsStyle.DBLineGraphS__Dates}>
                                    <p className='h10 fc-gray-400 default-cursor'>{formattedStartDate}</p>
                                    <p className='h10 fc-gray-400 default-cursor'>{formattedEndDate}</p>
                                </div>
                            </>
                    )
                }
            </div>
        </div>
    );
}

export const CartNPurchaseChart = (props) => {
    const { isLoading, noData, data, startDate, endDate } = props;
    const formattedStartDate = formatStartEndDate(startDate);
    const formattedEndDate = formatStartEndDate(endDate);
    const [series, setSeries] = useState(null);

    //const series = data?.multiSeries;

    const options = {
        ...DBLineGraphLOption,
        tooltip: {
            enabled: true,
            shared: true,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                const formattedDate = w.config.series[seriesIndex].data[dataPointIndex].x;

                const rawValueCart = w.config.series[0].data[dataPointIndex].y; 
                const formattedValueCart = !rawValueCart ? ( rawValueCart === null ? null : 0) : getFormattedNumberValue(rawValueCart);

                const rawValuePurchase = w.config.series[1].data[dataPointIndex].y; 
                const formattedValuePurchase = !rawValuePurchase ? (rawValuePurchase === null ? null : 0) : getFormattedNumberValue(rawValuePurchase);

                return DBLineGraphL_CustomTooltip(w, seriesIndex, formattedDate, [formattedValueCart, formattedValuePurchase], [graphColors.primary_200, graphColors.primary_800]);
              },
        },
    };

    useEffect(() => {
        if (data && Array.isArray(data[0].multiSeries)) {
            setSeries(data[0].multiSeries)
        }
    }, [data])


    return (
            <div className={dbChartsStyle.DBLineGraphL__Container}>
                {
                    isLoading ? (
                        <div className={dbChartsStyle.DBLineGraphL__NoChartWrap}>
                            <img src='/img/units/loading-ani-L.gif' width={572} height={322} style={{ objectFit: 'cover' }}/>
                        </div>
                    ) : (
                            (noData || !series) ? ( 
                                <div className={dbChartsStyle.DBLineGraphL__NoChartWrap}>
                                    <p className='h3 fc-gray-600'>No data</p>
                                </div>
                            ) :
                            <>
                                <Chart 
                                    options={options} 
                                    series={series} 
                                    type="line" 
                                    height={320}
                                />
                                <p className={`${dbChartsStyle.DBLineGraphL__Date__left} h9 fc-gray-600`}>{formattedStartDate}</p>
                                <p className={`${dbChartsStyle.DBLineGraphL__Date__right} h9 fc-gray-600`}>{formattedEndDate}</p>
                            </>
                    )
                }
            </div>
    );
}

export const FunnelChart = (props) => {
    const { isLoading, noData, data } = props;
    const [series, setSeries] = useState(null);

    const options = {
        ...DBBarGraphLOption,
        tooltip: {
            enabled: true,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                const rawValue = w.config.series[seriesIndex].data[dataPointIndex].y; 
                const formattedValue = getFormattedNumberWithSuffix(rawValue)
                
                return DBBarGraphL_CustomTooltip(w, seriesIndex, dataPointIndex, formattedValue, graphColors.primary_800);
              },
        },
    };

    useEffect(() => {
        if (data && Array.isArray(data[0].series.data)) {
            setSeries([{...data[0].series, name: '상품 탐색 퍼널'}]);
        }
    }, [data])

    return (
            <div className={dbChartsStyle.DBBarGraphL__Container}>
                {
                    isLoading ? (
                        <div className={dbChartsStyle.DBBarGraphL__NoChartWrap}>
                            <img src='/img/units/loading-ani-L.gif' width={839} height={472} style={{ objectFit: 'cover' }}/>
                        </div>
                    ) : (
                            (noData || !series) ? ( 
                                <div className={dbChartsStyle.DBBarGraphL__NoChartWrap}>
                                    <p className='h3 fc-gray-600'>No data</p>
                                </div>
                            ) :
                            <Chart 
                                options={options} 
                                series={series} 
                                type="bar" 
                                height={480} 
                            />
                    )
                }
            </div>
    );
}

