import { useEffect, useState } from 'react';
import agentStyle from '../../style/agent.module.css';
import { ColorInput, GreetingInput, LogoInput, NameInput, CarouselTypeInput, ExamplesInput } from '../Units/CustomSet';
import { Preview } from '../Units/Preview';

export const AgentCustom = (props) => {
    const {compProps} = props;
    const initSettings = compProps.initSettings;
    const updateSetting = compProps.updateSetting;
    const setIsContentOpen = compProps.setIsContentOpen;
    const setIsWidgetSetOpen = compProps.setIsWidgetSetOpen;
    const setClickedButton = compProps.setClickedButton;
    const setUnsavedChanges = compProps.setUnsavedChanges;
    const userAccessLevel = sessionStorage.getItem('l');
    let example = localStorage.getItem('example');
    const partnerId = sessionStorage.getItem('ptid');
    example = JSON.parse(example);

    const [carouselVisible, setCarouselVisible] = useState(false);

    return (
        <div className={agentStyle.AgentCustom__Container}>
            <div className={agentStyle.AgentCustom__Wrap}>
                <div className={agentStyle.AgentCustom__Left}>
                    <Preview initSettings={initSettings} carouselVisible={carouselVisible} />
                </div>
                <div className='spacing-48' />
                <div className={agentStyle.AgentCustom__Right}>
                    <LogoInput initLogo={initSettings?.profileImg} updateSetting={updateSetting} />
                    <div className='spacing-24' />
                    <NameInput initName={initSettings?.name} updateSetting={updateSetting} />
                    <div className='spacing-24' />
                    <ColorInput initColor={initSettings?.colorCode} updateSetting={updateSetting} setCarouselVisible={setCarouselVisible}/>
                    <div className='spacing-24' />
                    <GreetingInput initGreeting={initSettings?.greetingMessage} updateSetting={updateSetting}/>
                    <div className='spacing-24' />
                    <ExamplesInput initExamples={initSettings?.examples} updateSetting={updateSetting} partnerId={partnerId}/>
                    <div className='spacing-24' /> 
                    <CarouselTypeInput initCarouselType={initSettings?.carouselType} updateSetting={updateSetting} setCarouselVisible={setCarouselVisible}/>
                    <div className='spacing-48' />
                    <div className={agentStyle.AgentCustom__ButtonWrap}>
                        <button 
                            className={agentStyle.AgentCustom__Button} 
                            onClick={() => {
                                setIsContentOpen(false);
                                setIsWidgetSetOpen(true);
                                setClickedButton([true, false]);
                            }}
                        >
                            <p className='h4 fc-white'>다음</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}