import { useEffect, useState } from "react"
import { login, signup } from "../apis/api/auth/api";
import { useNavigate } from "react-router-dom";
import { getAccessLevel, storeInitData } from "../apis/services/init";
import signupStyle from '../style/signup.module.css';


const Signup = () => {
    // auto-fill
    const accountObj = JSON.parse(sessionStorage.getItem('accountObj'));
    const [enableCheck, setEnableCheck] = useState(false);
    const [checkStatus, setCheckStatus] = useState(false);
    const [enableButton, setEnableButton] = useState(false);
    const [inputList, setInputList] = useState({
        'signup-input-0': {
            id: 'signup-input-0',
            nameInput: 'email',
            type: 'email',
            labelText: '이메일(ID)',
            placeholder: 'abc@email.com',
            isValid: false,
            value: '',
            needGuideMsg: true,
            guideMsg: '안내를 위해 담당자 이메일을 정확히 입력해 주세요',
        },
        'signup-input-1': {
            id: 'signup-input-1',
            nameInput: 'password',
            type: 'password',
            labelText: '비밀번호',
            placeholder: '8~16자 이내 영문, 숫자, 특수문자 사용 가능',
            isValid: false,
            value: '',
            visibility: false,
        },
        'signup-input-2': {
            id: 'signup-input-2',
            nameInput: 'passwordConfirm',
            type: 'password',
            labelText: '비밀번호 확인',
            placeholder: '위 비밀번호와 동일하게 입력해 주세요',
            isValid: false,
            value: '',
            visibility: false,
        },
        'signup-input-3': {
            id: 'signup-input-3',
            nameInput: 'mallUrl',
            type: 'text',
            labelText: '쇼핑몰 주소',
            placeholder: 'www.gentooai.com',
            isValid: (accountObj?.mallUrl && true) || false,
            value: (accountObj?.mallUrl || ''),
        },
        'signup-input-4': {
            id: 'signup-input-4',
            nameInput: 'partnerCompanyName',
            type: 'text',
            labelText: '회사명',
            placeholder: '회사명을 입력해 주세요',
            isValid: (accountObj?.companyName && true) || false,
            value: (accountObj?.companyName || ''),
        },
        'signup-input-5': {
            id: 'signup-input-5',
            nameInput: 'manager',
            type: 'text',
            labelText: '담당자명',
            placeholder: '담당자명을 입력해 주세요',
            isValid: false,
            value: '',
            needGuideMsg: true,
            guideMsg: '안내를 위해 담당자 성함을 정확히 입력해 주세요',
        },
        'signup-input-6': {
            id: 'signup-input-6',
            nameInput: 'phoneNumber',
            type: 'text',
            labelText: '연락처(-제외)',
            placeholder: '01012341234',
            isValid: false,
            value: '',
            needGuideMsg: true,
            guideMsg: '안내를 위해 담당자 번호를 정확히 입력해 주세요',
        },
    });

    const updateInputList = (id, key, val) => {
        setInputList(prev => ({
            ...prev,
            [id]: {
                ...prev[id],
                [key]: val,
            }
        }));
    }

    const validLoop = () => {
        for (const [key, value] of Object.entries(inputList)) {
            if (!inputList[key].isValid) return false;
        }
        return true;
    }

    useEffect(() => {
        const res = validLoop();
        setEnableCheck(res);
        setEnableButton(res && checkStatus);
    }, [inputList, checkStatus])

    const handleClickCheck = () => {
        if (enableCheck) {
            setCheckStatus(!checkStatus);
        }
    }
    
    return (
        <div className={signupStyle.Signup__Container}>
            <div className={signupStyle.Signup__Wrap}>
                <div className={signupStyle.Signup__Header}>
                    <img src="/img/units/gentoo-symbol.png" width={56} height={56} />
                    <div className="spacing-24" />
                    <p className="h4 fc-gray-700">회원 정보를 입력해주세요</p>
                    <div className="spacing-8" />
                    <p className="h7 fc-gray-600">회원 가입을 완료하면 쇼핑몰 데이터를 학습한</p>
                    <p className="h7 fc-gray-600">인공지능 점원 젠투가 생성됩니다</p>
                </div>
                <div className="spacing-24" />
                <div className={signupStyle.Signup__InputSection}>
                    {
                        Object.keys(inputList).map((key, idx) => {
                            return (
                                <div key={idx}>
                                    <InputComp 
                                        idx={idx} 
                                        type={inputList[key].type} 
                                        nameInput={inputList[key].nameInput} 
                                        id={inputList[key].id} 
                                        labelText={inputList[key].labelText} 
                                        placeholder={inputList[key].placeholder} 
                                        value={inputList[key].value} 
                                        updateInputList={updateInputList}
                                        needGuideMsg={inputList[key].needGuideMsg || false}
                                        guideMsg={inputList[key].guideMsg || ''}
                                        visibility={inputList[key].visibility || false}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
                <div className="spacing-24" />
                <div className={signupStyle.Signup__Privacy}>
                    <div className={signupStyle.Signup__PrivacyCheck}>
                        <img src={enableCheck ? (checkStatus ? '/img/units/checkcircle-active-select.png' : '/img/units/checkcircle-active-unselect.png') : '/img/units/checkcircle-disabled.png'} width={20} height={20} onClick={handleClickCheck}/>
                    </div>
                    <p className={enableCheck ? "h10 fc-gray-700" : "h10 fc-gray-400"}>
                        상담을 위한{' '}
                        <a 
                            href="https://www.gentooai.com/?mode=privacy" 
                            className={enableCheck ? "h9 fc-gray-700" : "h9 fc-gray-400"}
                            style={{textDecoration: 'underline'}} 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            개인정보처리방침
                        </a>{' '}
                        동의 (필수)
                    </p>
                </div>
                <div className="spacing-24" />
                <SignupButton isActive={enableButton} signup={signup} inputList={inputList} accountObj={accountObj} />
            </div>
        </div>
    )
}

const InputComp = (props) => {
    const {idx, type, nameInput, id, labelText, placeholder, value, updateInputList, needGuideMsg, guideMsg, visibility} = props;
    const [errorState, setErrorState] = useState();

    const getHelpMessage = (helpType) => {
        if (helpType === null) return;
        const helpMessage = getErrorMessages(nameInput);
        if (helpType === 'error') {
            return (
                <div className={signupStyle.Signup__ErrorMsg}>
                    <div className={signupStyle.Signup__IconMsg}>
                        <img src='/img/units/input-error.png' width={13} height={13} />
                        <div className="spacing-4" />
                        <p className="h13 fc-warning">{helpMessage}</p>
                    </div>
                </div>
            )
        } else if (helpType === 'success') {
            return (
                <div className={signupStyle.Signup__GuideMsg}>
                    <div className={signupStyle.Signup__IconMsg}>
                        <img src='/img/units/input-success.png' width={13} height={13} />
                        <div className="spacing-4" />
                        <p className="h13 fc-positive-900">{helpMessage}</p>
                    </div>
                </div>
            )
        } else if (helpType === 'default') {
            return (
                <div className={signupStyle.Signup__GuideMsg}>
                    <p className="h13 fc-gray-500">{helpMessage}</p>
                </div>
            )
        } else {
            return;
        }
    }

    const getErrorMessages = (nameInput) => {
        switch (nameInput) {
            case 'email':
                return '올바른 형식의 이메일 주소를 입력해 주세요';
            case 'password':
                return '8~16자 이내 영문, 숫자, 특수문자만 사용 가능합니다';
            case 'passwordConfirm':
                return '비밀번호가 일치하지 않습니다';
            case 'phoneNumber':
                return '-를 제외하고 숫자만 입력해 주세요';
            default:
                return '';
        }
    }

    const validateInput = (e) => {
        const value = e.currentTarget.value;
        // const value = 'test@email.com'
        let exp;
        let res;
        switch (nameInput) {
            case 'email':
                exp = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
                res = new RegExp(exp).test(value);
                updateInputList(id, 'isValid', res);
                return (res ? '' : 'error');
            case 'password':
                exp = /^[a-zA-Z0-9\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]{8,16}$/;
                res = new RegExp(exp).test(value);
                updateInputList(id, 'isValid', res);
                return (res ? '' : 'error');
            case 'passwordConfirm':
                const password = document.getElementById('signup-input-1').value;
                res = password === value;
                updateInputList(id, 'isValid', res);
                return (res ? '' : 'error');
            case 'phoneNumber':
                exp = /^01(0|1|[6-9])[0-9]{3,4}[0-9]{4}$/;
                res = new RegExp(exp).test(value);
                updateInputList(id, 'isValid', res);
                return (res ? '' : 'error');
            default:
                res = value.length > 0;
                updateInputList(id, 'isValid', res);
                return '';
        }
    }

    return (
        <div className={signupStyle.Signup__InputComp}>
            <label htmlFor={id} className="h11 fc-gray-600" style={{marginBottom: '6px'}}>
                {labelText}
                <span className="h11 fc-warning">*</span>
            </label>
            {
                (id === 'signup-input-1' || id === 'signup-input-2') ?
                <div className={signupStyle.Signup__PwInputWrap}>
                    <input 
                        type={visibility ? 'text' : 'password'} name={nameInput} id={id} 
                        placeholder={placeholder}
                        value={value}
                        className={errorState === 'error' ? signupStyle.Signup__Input__error : signupStyle.Signup__Input}
                        onChange={(e) => {
                            const res = validateInput(e);
                            setErrorState(res);
                            updateInputList(id, 'value', e.currentTarget.value);
                        }}
                    />
                    <img 
                        src={visibility ? '/img/units/visibility-visible.png' : '/img/units/visibility-not-visible.png'} 
                        width={20} height={20} 
                        style={{position: 'absolute', right: '16px', cursor: 'pointer'}} 
                        onClick={() => {updateInputList(id, 'visibility', !visibility)}}
                        alt='visibility'
                    />
                </div> :
                <input 
                    type={type} name={nameInput} id={id} 
                    placeholder={placeholder}
                    value={value}
                    className={errorState === 'error' ? signupStyle.Signup__Input__error : signupStyle.Signup__Input}
                    onChange={(e) => {
                        const res = validateInput(e);
                        setErrorState(res);
                        updateInputList(id, 'value', e.currentTarget.value);
                    }}
                />
            }
            {
                errorState === 'error' ?
                    getHelpMessage(errorState)
                :
                    needGuideMsg ?
                        <div className={signupStyle.Signup__GuideMsg}>
                            <p className="h13 fc-gray-500">{guideMsg}</p>
                        </div>
                    :
                        null
            }
        </div>
    )
}

const SignupButton = (props) => {
    const {isActive, signup, inputList, accountObj} = props;
    const navigate = useNavigate();

    const handleSubmit = () => {
        signup(inputList, accountObj?.provider, (accountObj?.mallId || inputList['signup-input-0'].value), (accountObj?.shopUserId || inputList['signup-input-0'].value), (accountObj?.godomallMallId || inputList['signup-input-0'].value))
            .then(res => {
                if(res.success) {
                    sessionStorage.setItem('ptid', res.partnerId);
                    login(inputList['signup-input-0'].value, inputList['signup-input-1'].value)
                        .then(res => {
                            if (res.partnerId === undefined) {
                                alert('아이디 또는 비밀번호를 다시 확인하세요.');
                            } else {
                                storeInitData(res);
                                navigate('/home');
                            }
                        })
                        .catch(error => alert(error))
                }
            })
    }
    
    if (isActive) {
        return (
            <div className={signupStyle.Signup__Button__Wrap}>
                <button 
                    className={signupStyle.Signup__Button__active}
                    onClick={() => {handleSubmit()}}
                >
                    <p className="h6 fc-white">가입하기</p>
                </button>
            </div>
        )
    } else {
        return (
            <div className={signupStyle.Signup__Button__Wrap}>
                <button 
                    className={signupStyle.Signup__Button__disabled}
                    disabled={true}
                    onClick={(e) => {alert('rrr')}}
                >
                    <p className="h7 fc-gray-500">가입하기</p>
                </button>
            </div>
        )
    }
}

export default Signup;