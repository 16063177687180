import { forwardRef, useState } from "react";
import Toast from "../components/Units/Toast"
import DatePicker, { registerLocale } from "react-datepicker";
import { ko } from 'date-fns/locale/ko';
import "react-datepicker/dist/react-datepicker.css";
//import '../style/datePicker.css';

registerLocale('ko', ko)

const CompTest = () => {
    const [state, setState] = useState(false);

    // const testFunc1 = postScriptTest;
    // const testFunc2 = getScriptTest;

    return (
        <div style={{width: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <div>
                {/* <button onClick={() => testFunc1()}>testFunc1</button>
                <button onClick={() => testFunc2()}>testFunc2</button> */}
            </div>
        </div>
    )
}

export default CompTest