import React, { useEffect, useState } from 'react'
import { CustomPicker } from 'react-color'
import { EditableInput, Hue, Saturation } from 'react-color/lib/components/common'
import tinycolor2 from 'tinycolor2';
import customStyle from '../../style/units.module.css';

export const CustomColorPicker = (props) => {
  
    const { hex, onChange } = props;
    const [colorProps, setColorProps] = useState({
        hsl: tinycolor2(hex).toHsl(),
        hsv: tinycolor2(hex).toHsv(),
        hex: hex,
    });
    const [inputValue, setInputValue] = useState(hex);

    const handleSaturationChange = (hsv) => {
        const color = tinycolor2(hsv);
        setColorProps({
            ...colorProps,
            hsv: color.toHsv(),
            hsl: color.toHsl(),
            hex: color.toHexString(),
        });
        setInputValue(color.toHexString());
        const newColor = {
            hex: color.toHexString(),
            rgb: color.toRgb(),
            red: color.toRgb().r,
            green: color.toRgb().g,
            blue: color.toRgb().b,
            alpha: color.getAlpha(),
        }
        onChange(newColor);
    }

    const handleHueChange = (hsl) => {
        const newHsl = { h: hsl.h, s: colorProps.hsl.s, l: colorProps.hsl.l }
        const newHsv = { h: hsl.h, s: colorProps.hsv.s, v: colorProps.hsv.v }
        const color = tinycolor2(newHsv);
        setColorProps({
            ...colorProps,
            hsl: newHsl,
            hsv: newHsv,
            hex: color.toHexString(),
        });
        setInputValue(color.toHexString());
        const newColor = {
            hex: color.toHexString(),
            rgb: color.toRgb(),
            red: color.toRgb().r,
            green: color.toRgb().g,
            blue: color.toRgb().b,
            alpha: color.getAlpha(),
        }
        onChange(newColor);
    }

    const handleHexChange = (e) => {
        const color = tinycolor2(e);
        setColorProps({
            ...colorProps,
            hex: color.toHexString(),
            hsv: color.toHsv(),
            hsl: color.toHsl(),
        });
        setInputValue(color.toHexString());
        const newColor = {
            hex: color.toHexString(),
            rgb: color.toRgb(),
            red: color.toRgb().r,
            green: color.toRgb().g,
            blue: color.toRgb().b,
            alpha: color.getAlpha(),
        }
        onChange(newColor);
    }

    const CustomSlider = () => {
        return (
            <div 
                className={customStyle.CustomColorPicker__CustomSlider} 
                style={{ '--hsl-h': colorProps.hsl.h }}
            />
        )
    }

    const styles = {
        input: {
            width: 215,
            height: 37,
            border: `1px solid #e1e1e1`,
            padding: '8px 16px',
            alignItems: 'center',
            borderRadius: '0 5px 5px 0',
            color: '#222',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '19px',
            letterSpacing: '-0.375px',
        },
    }
    
    return (
        <div className={customStyle.CustomColorPicker__Container}>
            <div className={customStyle.CustomColorPicker__Saturation}>
                <Saturation 
                    hsl={colorProps.hsl}
                    hsv={colorProps.hsv}
                    onChange={handleSaturationChange}
                />
            </div>
            <div className='spacing-24'/>
            <div className={customStyle.CustomColorPicker__Hue}>
                <Hue 
                    hsl={colorProps.hsl} 
                    onChange={handleHueChange} 
                    pointer={CustomSlider}
                />
            </div>
            <div className='spacing-16'/>
            <div className={customStyle.CustomColorPicker__Hex}>
                <div 
                    className={customStyle.CustomColorPicker__Swatch} 
                    style={{ '--hex': hex }}
                />
                <EditableInput
                    style={{ input: styles.input }}
                    value={inputValue}
                    onChange={(e) => handleHexChange(e)}
                />
            </div>
        </div>
    )
}

export default CustomPicker(CustomColorPicker)