export const LIMIT_PROMPT_AMOUNT = 16;

export const customPromptDataInitial = {
    basicSettingData: [
        {
            id: null,
            type: 'company',
            activated: false,
            condition: '',
            answer: '',
        },
        {
            id: null,
            type: 'personal',
            activated: false,
            condition: '',
            answer: '',
        }
    ],
    productSettingData: 
        {
            id: null,
            type: "product",
            activated: true,
            condition: "",
            answer: ""
        }
    ,
    customerServiceSettingData: 
        {
            id: null,
            type: "cs",
            activated: true,
            condition: "",
            answer: ""
        }
    ,
}

export const accodianUIConfig = [
    {
        type: 'dataConfig',
        structure: {
            0: {
                id: 'basicSetting',
                accodianContent: 2,
            },
            1: {
                id: 'productSetting',
                accodianContent: 2,
            },
            2: {
                id: 'customerServiceSetting',
                accodianContent: 2,
            },
        }
    },
    {
        type: 'basicSettingData',
        accodianText: '기본 설정',
        tooltip: true,
        tooltipText: [
            '1) 회사 소개',
            '✅ 회사 개요 (비전, 미션 등)',
            '✅ 제공 제품·서비스 안내',
            '2) 페르소나 설정',
            '✅ 응답 스타일 (친절한, 전문적인 등)',
            '✅ 특정 캐릭터·브랜드 톤 설정',
        ],
        lists: [
            {
                inputType: 'company',
                recommended: true,
                recommendedText: '권장',
                condition: {
                    inputLabel: '회사 소개',
                    placeholder: '젠투몰은 남극을 비롯한 전세계 펭귄들을 위한 온라인 패션 플랫폼입니다. 다양한 펭귄 종들이 필요로 하는 필수 아이템을 한곳에서 편리하게 만나볼 수 있으며, 인공지능 기반의 스타일 추천 시스템과 맞춤형 커스터마이징 서비스를 통해 고객들에게 차별화된 쇼핑 경험을 제공합니다.',
                    height: '122px',
                    visible: true,
                },
                answer: {
                    inputLabel: '',
                    placeholder: '',
                    height: '0px',
                    visible: false,
                },
            },
            {
                inputType: 'personal',
                recommended: false,
                recommendedText: '선택',
                condition: {
                    inputLabel: '페르소나 설정',
                    placeholder: '너는 젠투몰의 친근하고 전문적인 퍼스널 쇼퍼야. 우리 고객들은 주로 20대 패션에 관심이 많은 펭귄이야. 답변할 때는 친근하지만 세련된 톤으로 이야기해 줘.',
                    height: '122px',
                    visible: true,
                },
                answer: {
                    inputLabel: '',
                    placeholder: '',
                    height: '0px',
                    visible: false,
                },
            },
        ]
    },
    {
        type: 'productSettingData',
        accodianText: '상품 관련',
        tooltip: true,
        tooltipText: [
            '✅ 상품 추천 시 강조할 정보',
            '✅ 상품 추천 시 주의사항',
            '🚫 추천 기준 변경 (인기순 등)',
            '🚫 실시간 재고·프로모션 반영',
        ],
        lists: [
            {
                inputType: 'product',
                recommended: false,
                recommendedText: '',
                condition: {
                    inputLabel: `질문 / 조건`,
                    placeholder: '아이들만 쓰는 제품인가요?',
                    height: '45px',
                    visible: true,
                },
                answer: {
                    inputLabel: '답변',
                    placeholder: `펭귄 스위치는 다양한 게임을 즐길 수 있어 어린이뿐만 아니라 성인들에게도 적합한 제품입니다.  
∙ 성인들에게 추천하는 이유: 펭귄 달리기, 펭귄 점프 같은 인기 게임뿐만 아니라 피트니스 게임도 가능해요   
∙ 모든 연령대에서 유용하게 사용할 수 있는 제품이에요! `,
                    height: '220px',
                    visible: true,
                },
            },
        ]
    },
    {
        type: 'customerServiceSettingData',
        accodianText: 'CS 관련',
        tooltip: true,
        tooltipText: [
            '✅ 배송·반품 정책, 운영시간 안내',
            '✅ FAQ 및 일반 문의 대응 안내',
            '🚫 개인 정보(구매·결제 내역) 처리',
            '🚫 주문건 환불·교환 개별 처리',
        ],
        lists: [
            {
                inputType: 'condition',
                recommended: false,
                recommendedText: '',
                condition: {
                    inputLabel: `질문 / 조건`,
                    placeholder: '고객 센터 운영시간은 언제인가요?',
                    height: '45px',
                    visible: true,
                },
                answer: {
                    inputLabel: '답변',
                    placeholder: `젠투몰 고객센터 운영시간은 오전 9시부터 오후 5시까지이며 운영시간 내 접수된 문의가 아니라면 당일 답변이 어려울 수 있어요. 고객센터 번호 : 010-1111-1111`,
                    height: '220px',
                    visible: true,
                },
            },
        ]
    },
]