import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/global.css';
import reportWebVitals from './reportWebVitals.js';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Main from './pages/Main.jsx';
import Dashboard from './components/Main/Dashboard.jsx';
import Analytics from './components/Analytics/Analytics.jsx';
import CompTest from './pages/CompTest.jsx';
import Prep from './components/Prep.jsx';
import { Provider } from 'react-redux';
import store from './redux/store.js'
import Login from './pages/Login.jsx';
import { ChatPreview } from './components/Agent/ChatPreview.jsx';
import Signup from './pages/Signup.jsx';
import Transaction from './pages/Transaction.jsx';
import DesignMode from './components/Agent/DesignMode.jsx';
import PasswordReset from './pages/PasswordReset.jsx';
import DeployPreview from './pages/DeployPreview.jsx';
import Experiment from './components/Experiment/Experiment.jsx';
import Setting from './components/Setting/Setting.jsx';
import General from './components/Setting/General.jsx';
import Plans from './components/Plans/Plans.jsx';
const basename = process.env.PUBLIC_URL;
const routes = [
  {
    path: '',
    element: <Transaction />,
  },
  {
    path: 'home',
    element: <Main />,
    children: [
      {
        path: '',
        element: <ChatPreview />,
      },
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'agent/custom/:partnerId',
        element: <DesignMode />,
      },
      {
        path: 'analytics',
        element: <Analytics />,
      },
      /* {
        path: 'usage',
        element: <Usage />
      }, */
      {
        path: 'experiment',
        element: <Experiment />,
      },
    ]
  },
  {
    path: 'setting',
    element: <Setting />,
    children: [
      {
        path: '',
        element: <General />,
      },
      {
        path: 'plans',
        element: <Plans />,
      },
      /* { 현재의 Usage 를 Plans 으로 변경한 후, 새로운 Usage 페이지를 구현해야 함
        path: 'usage',
        element: <Usage />,
      }, */
    ]
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'signup',
    element: <Signup />,
  },
  {
    path: 'test',
    element: <CompTest />
  },
  {
    path: 'reset',
    element: <PasswordReset />
  },
  {
    path: 'deploy-preview',
    element: <DeployPreview />
  }
]

const router = createBrowserRouter(routes, {basename: basename});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
