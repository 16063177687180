import chatPreviewStyle from '../../style/chatpreview.module.css';
import { useEffect, useRef, useState } from 'react';
import Onboarding from '../Units/Onboarding';
import { LogMonitor } from '../Units/LogMonitor';
import CustomPrompt from '../Units/CustomPrompt';
import { deployCustomPrompt, fetchCustomPrompt } from '../../apis/api/experiments/api';
import { ConfirmModal } from '../Units/Modal';
export const ChatPreview = () => {
    const isOnboardingDone = JSON.parse(sessionStorage.getItem('onboard'));
    const [isOnboardingEnable, setIsOnboardingEnable] = useState(isOnboardingDone); // 추후 결제 완료 state에 따라 TF 분기 필요
    const [networkLog, setNetworkLog] = useState([]);
    
    if (isOnboardingDone === null) {
        sessionStorage.setItem('onboard', true)
        setIsOnboardingEnable(true)
    }
    const partnerId = sessionStorage.getItem('ptid');
    const chatbotId = sessionStorage.getItem('cbid');
    const partnerType = sessionStorage.getItem('ptt');
    const initializeState = sessionStorage.getItem('initializeState');
    const isExpired = JSON.parse(sessionStorage.getItem('isExp'));
    const isPromptVisible = JSON.parse(sessionStorage.getItem('cpt'));
    const isDeployed = JSON.parse(sessionStorage.getItem('isDeployed'));
    
    // params for chatUrl build-up
    const toggleActiveStyle = chatPreviewStyle.AgentToggle__active;
    const toggleInactiveStyle = chatPreviewStyle.AgentToggle__inactive;
    const [tabStatus, setTabStatus] = useState([toggleActiveStyle, toggleInactiveStyle]);
    const logRef = useRef(null);
    const isDev = window.location.hostname !== 'console.gentooai.com' && window.location.hostname !== 'stage-console.gentooai.com';

    const handleClickToggle = (e) => {
        const selectedId = e.target.className;
        if (selectedId.includes('toggle-mobile')) {setTabStatus([toggleActiveStyle, toggleInactiveStyle])}
        else {setTabStatus([toggleInactiveStyle, toggleActiveStyle])}
    }

    useEffect(() => {
        window.addEventListener('message', (e) => {
            if (e.data.type === 'chatLog') {
                setNetworkLog(prev => [...prev, e.data.chatLog]);
            }
        })
    }, [])

    useEffect(() => {
        if (logRef.current) {
            logRef.current.scrollTo({
                top: logRef.current.scrollHeight,
                behavior: 'smooth'
            })
        }
    }, [networkLog])

    const getChatPreview = () => {
        if (isExpired) {
            return (
                <div className={chatPreviewStyle.ChatPreview__Container}>
                    <div className={chatPreviewStyle.ChatPreview__Block}>
                        <p className='h4 fc-gray-600'>체험 기간이 종료되었습니다</p>
                        <div className='spacing-8' />
                        <p className='h8 fc-gray-600'>‘유료 전환 신청하기’ 버튼을 통해<br />에이전트를 활성화할 수 있습니다</p>
                        <div className='spacing-24' />
                        <button 
                            className={chatPreviewStyle.ChatPreview__Block__button}
                            onClick={() => window.open('https://whattime.co.kr/waddle/pricing-consult', "_blank")}
                        >
                            <div className={chatPreviewStyle.ChatPreview__Block__textWrap}>
                                <p className='h6 fc-prim-800'>유료 전환 신청하기</p>
                                <div className='spacing-8' />
                                <img src='/img/units/send-prim.png' width={20} height={20} />
                            </div>
                        </button>
                    </div>
                </div>
            )
        } else if (tabStatus[0] === toggleActiveStyle) {
            return (
                <div className={chatPreviewStyle.ChatPreview__Container}>
                    <div className={chatPreviewStyle.ChatPreview__Block__iframeWrap__mobile}>
                        <iframe 
                            title='chat-preview-mobile'
                            className={chatPreviewStyle.ChatPreview__Block__iframe}
                            src={initializeState > 1 ? `${process.env.REACT_APP_CHAT_URL}/chatroute/${encodeURIComponent(partnerType)}?ptid=${partnerId}&ch=${true}&isConsole=true` : `${process.env.REACT_APP_CHAT_URL}/chatroute/dummy?ptid=6718be2310310e41ab5276ef&isConsole=true`} 
                            // src={initializeState > 1 ? `${process.env.REACT_APP_CHAT_URL}/chatroute/${encodeURIComponent(partnerType)}?ptid=${partnerId}&ch=${true}` : `http://localhost:3000/chatroute/dummy?ptid=6718be2310310e41ab5276ef`} 
                        />
                    </div>
                    {
                        // isDev &&
                        // <LogMonitor logRef={logRef} networkLog={networkLog} />
                    }
                </div>
            )
        } else {
            return (
                <div className={chatPreviewStyle.ChatPreview__Container}>
                    <div className={chatPreviewStyle.ChatPreview__Block__iframeWrap}>
                        <iframe 
                            title='chat-preview-desktop'
                            className={chatPreviewStyle.ChatPreview__Block__iframe}
                            src={initializeState > 1 ? `${process.env.REACT_APP_CHAT_URL}/chatroute/${encodeURIComponent(partnerType)}?ptid=${partnerId}&ch=${false}&isConsole=true` : `${process.env.REACT_APP_CHAT_URL}/chatroute/dummy?ptid=6718be2310310e41ab5276ef&isConsole=true`} />
                    </div>
                </div>
            )
        }
    }
    
    return (
        <div style={{width: '100%', maxWidth: '100%', height: '100%', display: 'flex', flexDirection: 'row'}}>
            {
                isOnboardingEnable && !isDeployed &&
                <Onboarding setIsOnboardingEnable={setIsOnboardingEnable} />
            }
            <div className={chatPreviewStyle.AgentToggle__Container}>
                {
                        !isPromptVisible &&    
                        <div className={chatPreviewStyle.AgentToggle__Wrap}>
                            <div 
                                className={`${tabStatus[0]} toggle-mobile`} 
                                onClick={handleClickToggle}
                            >
                                <img src={tabStatus[0] === toggleActiveStyle ? '/img/units/icon-mobile.png' : '/img/units/icon-mobile-gray400.png'} width={12} height={12} />
                                <div className='spacing-4' />
                                <p className='h14 toggle-mobile'>모바일</p>
                            </div>
                            <div 
                                className={`${tabStatus[1]} toggle-desktop`} 
                                onClick={handleClickToggle}
                            >
                                <img src={tabStatus[1] === toggleActiveStyle ? '/img/units/icon-desktop.png' : '/img/units/icon-desktop-gray400.png'} width={12} height={12} />
                                <div className='spacing-4' />
                                <p className='h14 toggle-desktop'>데스크탑</p>
                            </div>
                        </div>
                }
                {getChatPreview()}
            </div>
            {
                isPromptVisible &&
                <CustomPrompt />
            }
        </div>
    )
}