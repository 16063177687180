import { useState } from 'react';
import previewStyle from '../../style/units.module.css';
import { Carousel } from './Carousel';

export const Preview = (props) => {
    const {initSettings, carouselVisible} = props;
    const followUpQuestions = ['성분 확인하기', '비슷한 상품 추천받기', '섭취 방법 알려줘'];

    return (
        <div className={previewStyle.Preview__Container}>
            <div className={previewStyle.Preview__Wrap}>
                <div className={previewStyle.Preview__Header} />
                <div className={previewStyle.Preview__Contents}>
                    <div className={previewStyle.Preview__GreetingWrap}>
                        {
                            carouselVisible &&
                            <>
                            <div className={previewStyle.Preview__Bubble__userWrap}>
                                <div 
                                    className={previewStyle.Preview__Bubble__user} 
                                    style={{
                                        '--colorCode-0-r': initSettings?.colorCode[0].red,
                                        '--colorCode-0-g': initSettings?.colorCode[0].green,
                                        '--colorCode-0-b': initSettings?.colorCode[0].blue,
                                        '--colorCode-0-a': initSettings?.colorCode[0].alpha
                                    }}
                                >
                                    <p className='h7 fc-white'>눈 건강에 좋은 영양제 추천해 주세요!</p>
                                </div>
                            </div>
                            <div className='spacing-16' />
                            </>
                        }
                        <div className={previewStyle.Preview__Greeting}>
                            <img src={initSettings?.profileImg} width={32} height={32}/>
                            <div className='spacing-8' />
                            <div className={previewStyle.Preview__BubbleWrap}>
                                <p className='h13 fc-gray-500'>{initSettings?.name}</p>
                                <div className='spacing-4' />
                                <div className={previewStyle.Preview__Bubble__bot}>
                                    {
                                        carouselVisible ?
                                        <p className='h7 fc-gray-800'>A,B,C 제품은 눈 건강을 위한 영양제로  
                                        <b>루테인</b>과 <b>아스타잔틴</b>, 그리고 <b>헤마토코쿠스 추출물</b>이 함유되어 있습니다. 해당 성분들은
                                        시력 유지에 도움을 주고 눈의 피로도 개선에도
                                        효과적이라 추천드려요! 💪</p> :
                                        <p className='h7 fc-gray-800'>{initSettings?.greetingMessage}</p>
                                    }
                                </div>
                                {
                                    carouselVisible &&
                                    <>
                                    <div className='spacing-4' />
                                    <div className={previewStyle.Preview__Bubble__bot__followUp}>
                                        <p className='h7 fc-gray-800'><b>A,B,C 제품</b>에 관해 더 자세한 설명이 필요하신가요?</p>
                                    </div>
                                    </>
                                }
                                <div className='spacing-16'/>
                                {
                                    !carouselVisible &&
                                    <div className={previewStyle.Preview__Bubble__userWrap}>
                                        <div 
                                            className={previewStyle.Preview__Bubble__user}
                                            style={{
                                                '--colorCode-0-r': initSettings?.colorCode[0].red,
                                                '--colorCode-0-g': initSettings?.colorCode[0].green,
                                                '--colorCode-0-b': initSettings?.colorCode[0].blue,
                                                '--colorCode-0-a': initSettings?.colorCode[0].alpha
                                            }}
                                        >
                                            <p className='h7 fc-white'>사용자 발화 텍스트 미리보기입니다</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='spacing-4' />
                </div>
                {
                    carouselVisible &&
                    <div className={previewStyle.Preview__Display}>
                        <div className={previewStyle.Preview__Display__Divider} />
                        <div className={previewStyle.Preview__Display__Fold}>
                            <img 
                                src='/img/display/display-close.png' 
                                width={24} 
                                height={24} 
                                className={previewStyle.Preview__Display__Toggle}
                            />
                        </div>
                        <div className={previewStyle.Preview__Display__Contents}>
                            <Carousel type={initSettings?.carouselType} />
                        </div>
                        <div className={previewStyle.Preview__Display__IndicatorWrap}>
                            {
                                initSettings?.carouselType === 'single' ?
                                <img 
                                    src='/img/units/carousel-indicator-line.png' 
                                    width={95} 
                                    height={4} 
                                    alt='carousel-indicator'
                                /> :
                                <div className='spacing-4'/>
                            }
                        </div>
                    </div>
                }
                <div className={previewStyle.Preview__Bottom}>
                    <div className={previewStyle.Preview__ExampleContainer}>
                        <div className={previewStyle.Preview__ExampleWrap}>
                            {
                                carouselVisible ?
                                followUpQuestions.map((question, idx) => {
                                    return (
                                        <div 
                                            className={previewStyle.Preview__Example} 
                                            key={idx}
                                            style={{
                                                '--colorCode-0-r': initSettings?.colorCode[0].red,
                                                '--colorCode-0-g': initSettings?.colorCode[0].green,
                                                '--colorCode-0-b': initSettings?.colorCode[0].blue,
                                                '--colorCode-0-a': initSettings?.colorCode[0].alpha,
                                                '--colorCode-1-r': initSettings?.colorCode[1].red,
                                                '--colorCode-1-g': initSettings?.colorCode[1].green,
                                                '--colorCode-1-b': initSettings?.colorCode[1].blue,
                                                '--colorCode-1-a': initSettings?.colorCode[1].alpha,
                                                '--colorCode-2-r': initSettings?.colorCode[2].red,
                                                '--colorCode-2-g': initSettings?.colorCode[2].green,
                                                '--colorCode-2-b': initSettings?.colorCode[2].blue,
                                                '--colorCode-2-a': initSettings?.colorCode[2].alpha
                                            }}
                                        >
                                            <p className='h7'>{question}</p>
                                        </div>
                                    )
                                }) :
                                initSettings?.examples?.map((exam, idx) => {
                                    return (
                                        <div 
                                            className={previewStyle.Preview__Example} 
                                            key={idx}
                                            style={{
                                                '--colorCode-0-r': initSettings?.colorCode[0].red,
                                                '--colorCode-0-g': initSettings?.colorCode[0].green,
                                                '--colorCode-0-b': initSettings?.colorCode[0].blue,
                                                '--colorCode-0-a': initSettings?.colorCode[0].alpha,
                                                '--colorCode-1-r': initSettings?.colorCode[1].red,
                                                '--colorCode-1-g': initSettings?.colorCode[1].green,
                                                '--colorCode-1-b': initSettings?.colorCode[1].blue,
                                                '--colorCode-1-a': initSettings?.colorCode[1].alpha,
                                                '--colorCode-2-r': initSettings?.colorCode[2].red,
                                                '--colorCode-2-g': initSettings?.colorCode[2].green,
                                                '--colorCode-2-b': initSettings?.colorCode[2].blue,
                                                '--colorCode-2-a': initSettings?.colorCode[2].alpha
                                            }}
                                        >
                                            <p className='h7'>{exam}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className={previewStyle.Preview__InputContainer}>
                        <div className={previewStyle.Preview__InputWrap}>
                            <img src='/img/units/preview-reset.png' width={32} height={32} />
                            <div className='spacing-8' />
                            <div className={previewStyle.Preview__Input}>
                                <p className='h7 fc-gray-500'>무엇이든 물어보세요</p>
                                <img src='/img/units/preview-send.png' width={28} height={28}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}