import { useState, useEffect } from 'react';
import settingStyle from '../../style/setting.module.css';
import agentStyle from '../../style/agent.module.css';
import { TopAccodian } from '../Units/Accodian';
import { InputField, CopyField } from '../Units/InputField';
import Toast from '../Units/Toast';
import { ConsoleFooter } from '../Units/ConsoleFooter.jsx';
const General = () => {

    const [isDefaultInfoOpen, setIsDefaultInfoOpen] = useState(true);
    const [isExternalKeyOpen, setIsExternalKeyOpen] = useState(true);
    const [isCopyToastVisible, setIsCopyToastVisible] = useState(false);

    const defaultInfoProps = {
        isContentOpen: isDefaultInfoOpen,
        setIsContentOpen: setIsDefaultInfoOpen,
    }

    const externalKeyProps = {
        isContentOpen: isExternalKeyOpen,
        setIsContentOpen: setIsExternalKeyOpen,
        setIsCopyToastVisible: setIsCopyToastVisible,
    }

    return (
        <div className={settingStyle.General__Container}>
            <div className={settingStyle.General__MinHeightWrap}>
                <div className={settingStyle.General__GeneralSetting}>
                    <TopAccodian 
                        title={"기본 정보"}
                        isBadgeText={false}
                        badgeIcon={"/img/units/icon-default-info.png"}
                        ContentComp={DefaultInfo}
                        subText={"쇼핑몰의 기본 정보를 관리할 수 있습니다"}
                        compProps={defaultInfoProps}
                    />
                    <div className="spacing-32" />
                    <TopAccodian
                        title={"External Key"}
                        isBadgeText={false}
                        badgeIcon={"/img/units/icon-external-key.png"}
                        ContentComp={ExternalKey}
                        subText={"SDK 연동 및 데이터 업로드를 위한 External key를 확인할 수 있습니다"}
                        compProps={externalKeyProps}
                    />
                </div>
            </div>
            <ConsoleFooter />
            {
                isCopyToastVisible &&
                <div className={settingStyle.Toast__Block}>
                    <Toast status={'copy'} message={'클립보드에 복사되었습니다'} isLinkable={false} isVisible={isCopyToastVisible} setIsVisible={setIsCopyToastVisible} />
                </div>
            }
        </div>
    )
}

export const DefaultInfo = () => {

    const email = sessionStorage.getItem('email');

    return (
        <>
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', padding: '0px', gap: '20px'}}>
                <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                    <CopyField 
                        name='email'
                        type='text' 
                        labelText='이메일(ID)' 
                        value={email}
                        copyIcon={false}
                    />
                </div>
            </div>
            {/* <div className={agentStyle.AgentCustom__ButtonWrap} style={{padding: '48px 0 12px 0'}}>
                <button 
                    className={agentStyle.AgentCustom__Button} 
                    onClick={() => {}}
                >
                    <p className='h4 fc-white'>저장</p>
                </button>
            </div> */}
            <div className='spacing-12'/>
        </>
    )
}

export const ExternalKey = (props) => {

    const {compProps} = props;
    const setIsCopyToastVisible = compProps.setIsCopyToastVisible;

    // external key = shopId
    const externalKey = sessionStorage.getItem('sid');

    const handleCopy = () => {
        navigator.clipboard.writeText(externalKey);
        setIsCopyToastVisible(true);
    }

    return (
        <>
            <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                <CopyField 
                    name='external-key'
                    type='text' 
                    labelText='External Key' 
                    value={externalKey}
                    handleCopy={handleCopy}
                />
            </div>
            <div className='spacing-12'/>
        </>
    )
}

export default General;