import mainStyle from '../style/pages/main.module.css';
import unitStyle from '../style/units.module.css';
import '../style/global.css';
import { TopNav } from '../components/Main/TopNav';
import { Link, Outlet, useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { TimerBanner } from '../components/Units/TimerBanner';
import { postAdminRedirect } from "../apis/api/auth/api";
import { storeInitData } from "../apis/services/init";
import ScrollToTop from '../components/Units/ScrollToTop';

const Main = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = window.location.pathname;
    const queryString = window.location.search;
    const [searchParams, setSearchParams] = new useSearchParams(queryString);
    const [isLogin, setIsLogin] = useState(false);
    const [tryLogout, setTryLogout] = useState(false);
    const [selectedList, setSelectedList] = useState(localStorage.getItem('p') || 0);
    const [clientWidth, setClientWidth] = useState(0);
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const username = 'demo';
    const partnerId = sessionStorage.getItem('ptid');
    const userAccessLevel = sessionStorage.getItem('l');
    const initializeState = sessionStorage.getItem('initializeState');
    const adminRedirect = searchParams.get('adminRedirect');

    const routes = {
        top: [
            {
                id: 0,
                path: '/home',
                name: '젠투 에이전트',
                imgSrc: '/img/main/main-gentoo-agent.png',
                accessLevel: 0,
            },
            {
                id: 1,
                path: `/home/agent/custom/${encodeURIComponent(partnerId)}`,
                name: '디자인 모드',
                imgSrc: '/img/main/main-designmode.png',
                accessLevel: 0,
            },
            {
                id: 2,
                path: '/home/dashboard',
                name: '대시보드',
                imgSrc: '/img/main/main-dashboard.png',
                accessLevel: 0,
            },
            {
                id: 3,
                path: '/home/analytics',
                name: '분석',
                imgSrc: '/img/main/main-analytics.png',
                accessLevel: 0,
                // child: [
                //     {
                //         id: 0,
                //         path: `/analytics`,
                //         name: '젠투 에이전트 분석',
                //     }
                // ]
            },
        ],
        bottom: [
            {
                id: 5,
                path: 'https://waddlelab.notion.site/16d1f2d71bda80e09127d9198078dbb0?pvs=4',
                name: '개발 가이드',
                imgSrc: '/img/main/main-guide.png',
                accessLevel: 0,
            },
            {
                id: 6,
                path: '/setting',
                name: '설정',
                imgSrc: '/img/main/main-setting.png',
                accessLevel: 0,
            },
            {
                id: 7,
                path: '/home/experiment',
                name: '실험실',
                imgSrc: '/img/main/main-experiment.png',
                accessLevel: 0,
            },
        ]
    };

    const [showAgentMenus, setShowAgentMenus] = useState(false);

    useEffect(() => {
        setClientWidth(document.getElementById('main-layout')?.clientWidth);
        if (adminRedirect) {
            postAdminRedirect(adminRedirect)
                .then(res => {
                    storeInitData(res);
                    navigate('/home');
                })
                .catch(error => alert(error))
        } else if (partnerId) {
            setIsLogin(true);
        }
    }, []);

    const handleNavigate = (path, state) => {
        navigate(path, state);
    }

    const handleToggleSubMenu = (id) => {
        setActiveSubMenu(activeSubMenu === id ? null : id);
    }

    const handleLogout = (e) => {
        e.preventDefault();
        const target = e.currentTarget.id;

        if (target === 'menu7') {
            sessionStorage.clear();
            navigate('/login')
        }
    }

    return (
        <>
            <div className={mainStyle.Main__Background}>
                {
                    !isLogin ?
                        navigate('/login') :
                        <div className={mainStyle.Main__Wrap}>
                            <div className={mainStyle.Main__SideBar__Wrap}>
                                <div className={mainStyle.Main__SideBar}>
                                    <div className={mainStyle.Main__SideBar__TopMenuBox}>
                                        <div className={mainStyle.Main__SideBar__LogoBox}>
                                            <img src='/img/main/main-logo.png' width={115} />
                                        </div>
                                        <div className='spacing-16' />
                                        <div>
                                            {routes.top.map((route, idx) => (
                                                // accesslevel local test
                                                (route.accessLevel <= Number(userAccessLevel) &&
                                                    <div className={mainStyle.Main__List} key={idx}>
                                                        <div className={unitStyle.Flex__HLeft}>
                                                            <input
                                                                className={mainStyle.Main__List__MenuInput}
                                                                id={`menu${route.id + 1}`}
                                                                type='radio'
                                                                name='menu'
                                                                onClick={() => {
                                                                    handleNavigate(route.path);
                                                                    if (route.child) {
                                                                        handleToggleSubMenu(route.id)
                                                                    }
                                                                }}
                                                                checked={!route.child && pathname === route.path}
                                                                onChange={() => {}}
                                                            />
                                                            <label className={`${mainStyle.Main__List__MenuLabel} pointer`} htmlFor={`menu${route.id + 1}`}>
                                                                <img src={route.imgSrc} width={18} />
                                                                <div className='spacing-16' />
                                                                <p className='h9 fc-gray-600'>{route.name}</p>
                                                                {
                                                                    route.child &&
                                                                    <>
                                                                        <div className='spacing-16' />
                                                                        <img src='/img/main/main-downarrow.png' width={24} height={24} />
                                                                    </>
                                                                }
                                                            </label>
                                                        </div>
                                                        {route.child && activeSubMenu === route.id && (
                                                            <div className={mainStyle.Main__SubMenu}>
                                                                {route.child.map((subRoute, subIdx) => (
                                                                    <div className={mainStyle.Main__List} key={subIdx}>
                                                                        <input
                                                                            className={mainStyle.Main__List__MenuInput}
                                                                            id={`submenu${subRoute.id}`}
                                                                            type='radio'
                                                                            name='submenu'
                                                                            onClick={() => handleNavigate(subRoute.path)}
                                                                            checked={pathname === subRoute.path}
                                                                            onChange={() => {}}
                                                                        />
                                                                        <label className={`${mainStyle.Main__List__SubLabel__Content} pointer`} htmlFor={`submenu${subRoute.id}`}>
                                                                            <div className='spacing-16' />
                                                                            <p className='h7 fc-gray-600'>{subRoute.name}</p>
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            ))}
                                        </div>
                                    </div>
                                    <div>
                                        <TimerBanner userAccessLevel={Number(userAccessLevel)} initializeState={Number(initializeState)} />
                                        <div className='spacing-24' />
                                        <div className={mainStyle.Main__SideBar__BottomMenuBox}>
                                            {/* <div className={mainStyle.Main__List}>
                                                <div className={unitStyle.Flex__HLeft}>
                                                    <input
                                                        className={mainStyle.Main__List__MenuInput}
                                                        id={`menu${routes.bottom[0].id + 1}`}
                                                        type='radio'
                                                        name='menu'
                                                        onClick={() => {
                                                            handleNavigate(routes.bottom[0].path);
                                                            if (routes.bottom[0].child) {
                                                                handleToggleSubMenu(routes.bottom[0].id)
                                                            }
                                                        }}
                                                        checked={!routes.bottom[0].child && pathname === routes.bottom[0].path}
                                                        onChange={() => {}}
                                                    />
                                                    <label className={`${mainStyle.Main__List__MenuLabel} pointer`} htmlFor={`menu${routes.bottom[0].id + 1}`}>
                                                        <img src={routes.bottom[0].imgSrc} width={18} />
                                                        <div className='spacing-16' />
                                                        <p className='h9 fc-gray-600'>{routes.bottom[0].name}</p>
                                                        {
                                                            routes.bottom[0].child &&
                                                            <>
                                                                <div className='spacing-16' />
                                                                <img src='/img/main/main-downarrow.png' width={24} height={24} />
                                                            </>
                                                        }
                                                    </label>
                                                </div>
                                            </div> */}
                                            <div className={mainStyle.Main__List}>
                                                <div className={unitStyle.Flex__HLeft}>
                                                    <input
                                                        className={mainStyle.Main__List__MenuInput}
                                                        id={`menu${routes.bottom[0].id + 1}`}
                                                        type='radio'
                                                        name='menu'
                                                        onClick={() => {
                                                            window.open(`${routes.bottom[0].path}`, '_blank');
                                                        }}
                                                    />
                                                    <label className={`${mainStyle.Main__List__MenuLabel} pointer`} htmlFor={`menu${routes.bottom[0].id + 1}`}>
                                                        <img src={routes.bottom[0].imgSrc} width={18} />
                                                        <div className='spacing-16' />
                                                        <p className='h9 fc-gray-600'>{routes.bottom[0].name}</p>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className={mainStyle.Main__List}>
                                                <div className={unitStyle.Flex__HLeft}>
                                                    <input className={mainStyle.Main__List__MenuInput} id={`menu${routes.bottom[2].id + 1}`} type='radio' name='menu' onClick={() => { handleNavigate(routes.bottom[2].path); }} />
                                                    <label className={`${mainStyle.Main__List__MenuLabel} pointer`} htmlFor={`menu${routes.bottom[2].id + 1}`}>
                                                        <img src={routes.bottom[2].imgSrc} width={18} />
                                                        <div className='spacing-16' />
                                                        <p className='h9 fc-gray-600'>{routes.bottom[2].name}</p>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className={mainStyle.Main__List}>
                                                <div className={unitStyle.Flex__HLeft}>
                                                    <input 
                                                        className={mainStyle.Main__List__MenuInput} 
                                                        id={`menu${routes.bottom[1].id + 1}`} 
                                                        type='radio' 
                                                        name='menu' 
                                                        onClick={() => { 
                                                            sessionStorage.setItem('previousPath', location.pathname);
                                                            handleNavigate(routes.bottom[1].path);
                                                        }} 
                                                    />
                                                    <label className={`${mainStyle.Main__List__MenuLabel} pointer`} htmlFor={`menu${routes.bottom[1].id + 1}`}>
                                                        <img src={routes.bottom[1].imgSrc} width={18} />
                                                        <div className='spacing-16' />
                                                        <p className='h9 fc-gray-600'>{routes.bottom[1].name}</p>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={mainStyle.Main__Container}>
                                <TopNav />
                                <div className={mainStyle.Main__OutletWrap} id="outlet-wrap">
                                    <ScrollToTop containerId="outlet-wrap"/>
                                    <Outlet className={mainStyle.Main__Outlet}/>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </>
    )
};

export default Main